import { DialogModule } from '@angular/cdk/dialog';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ButtonIconComponent } from './components/button-icon/button-icon.component';
import { ButtonToggleComponent } from './components/button-toggle/button-toggle.component';
import { ButtonComponent } from './components/button/button.component';
import { CardComponent } from './components/card/card.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DatePickerComponent } from './components/date/date-picker/date-picker.component';
import { DateComponent } from './components/date/date.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { IconComponent } from './components/icon/icon.component';
import { InformationComponent } from './components/information/information.component';
import { InputComponent } from './components/input/input.component';
import { LayoutComponent } from './components/layout/layout.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModalComponent } from './components/modal/modal.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { SelectComponent } from './components/select/select.component';
import { SelectionComponent } from './components/selection/selection.component';
import { SlideToggleComponent } from './components/slide-toggle/slide-toggle.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SynSnackBarComponent } from './components/syn-snack-bar/syn-snack-bar.component';
import { TableComponent } from './components/table/table.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TreeComponent } from './components/tree/tree.component';
import { DatePipe } from './pipes/date.pipe';
import { PermissionPipe } from './pipes/droit.pipe';
import { FileSizePipe, ImageFilePipe, PreviewFilePipe } from './pipes/file.pipe';
import { SnackBarIconPipe } from './pipes/snack-bar.pipe';
import { ConnectedUserPipe } from './pipes/user.pipe';

@NgModule({
  declarations: [
    // Components
    DialogComponent,
    SynSnackBarComponent,
    CardComponent,
    InformationComponent,
    DateComponent,
    LayoutComponent,
    PaginationComponent,
    TableComponent,
    TreeComponent,
    FileUploadComponent,
    // Pipes
    DatePipe,
    SnackBarIconPipe,
    PermissionPipe,
    ConnectedUserPipe,
    ImageFilePipe,
    PreviewFilePipe,
    FileSizePipe,
    SpinnerComponent,
    InputComponent,
    FormErrorsComponent,
    SlideToggleComponent,
    CheckboxComponent,
    SelectComponent,
    ButtonComponent,
    ModalComponent,
    MenuComponent,
    SelectionComponent,
    IconComponent,
    RadioButtonComponent,
    ButtonToggleComponent,
    TabsComponent,
    TabComponent,
    ButtonIconComponent,
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    PortalModule,
    MatToolbarModule,
    MatSidenavModule,
    DialogModule,
    MatNativeDateModule,
    TranslateModule.forChild(),
    NgxSpinnerModule,
    OverlayModule,
    CdkMenuModule,
    CdkTreeModule,
    CdkTableModule
  ],
  exports: [
    // Modules
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    PortalModule,
    MatToolbarModule,
    MatSidenavModule,
    DialogModule,
    RouterModule,
    MatNativeDateModule,
    NgxSpinnerModule,
    OverlayModule,
    CdkMenuModule,
    CdkTreeModule,
    // Pipes
    DatePipe,
    PermissionPipe,
    ConnectedUserPipe,
    ImageFilePipe,
    PreviewFilePipe,
    FileSizePipe,
    // Components
    DialogComponent,
    DateComponent,
    CardComponent,
    InformationComponent,
    LayoutComponent,
    PaginationComponent,
    TableComponent,
    TreeComponent,
    FileUploadComponent,
    SlideToggleComponent,
    InputComponent,
    CheckboxComponent,
    SelectComponent,
    SpinnerComponent,
    ButtonComponent,
    ModalComponent,
    MenuComponent,
    SelectionComponent,
    IconComponent,
    RadioButtonComponent,
    ButtonToggleComponent,
    TabsComponent,
    TabComponent,
    ButtonIconComponent,
    DatePickerComponent
  ],
  providers: [
  ]
})
export class SharedModule { }
