import { Inject, Injectable } from "@angular/core";
import { Page, PageSize } from './../models/filter.interface';

@Injectable({
    providedIn: 'root'
})
export class ApiBaseService {

    protected baseUrl: string;

    constructor(@Inject(String) url: string) {
        this.baseUrl = url;
    }

    protected getRelationsQuery(query: string = '', relations: string[] = []): string {
        if (relations.length) {
            const del = query.includes('?') ? '&' : '?';
            return `${query}${del}relations=${relations.join(',')}`;
        }
        return query;
    }

    protected getPaginationQuery(query: string = '', page?: Page): string {
        page = page ?? { currentPage: 1, pageSize: PageSize.SIZE_20 };
        const del = query.includes('?') ? '&' : '?';
        const skip = (page.currentPage - 1) * page.pageSize;
        return `${query}${del}skip=${skip}&take=${page.pageSize}`;
    }

    protected get publicRequestHeader() {
        return { surqo: 'public' };
    }
}