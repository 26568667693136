<div fxLayout="column" fxLayoutAlign="space-around start" class="syn-select">

    <label class="label" [class.label-required]="required" *ngIf="label?.length" [for]="idControl">{{label}}</label>

    <div fxLayout="row" class="fullW" fxLayoutAlign="start center" #wrapper>
        <input class="syn-select-input icon-info pointer" (click)="computeMenuWidth()"
            [class.has-errors]="errors && field.touched" [id]="idControl" [name]="idControl"
            [value]="selectedLabel | translate"
            [placeholder]="this.placeholder?.length ? this.placeholder : ('shared.selectionner' | translate)"
            [readonly]="true" (blur)="onBlur()" [required]="required" [cdkMenuTriggerFor]="menu" />

        <button type="button" *ngIf="labelInfo?.length" (click)="clickOnInfo.next(value)"
            class="select-suffix-btn pointer" [class.info-text]="!withIcon" role="button">
            <ng-container *ngIf="!withIcon">{{labelInfo}}</ng-container>
            <syn-icon [title]="labelInfo" *ngIf="withIcon" classes="tWhite" size="lg" [name]="icon"></syn-icon>
        </button>
    </div>
    <ng-template #menu>
        <div fxLayout="column" [style.width.px]="width" fxLayoutAlign="start start" class="syn-select-options fullW"
            cdkMenu>
            <span *ngIf="showPlaceholder" class="option pointer fullW placeholder" (click)="select(null)" cdkMenuItem>
                {{ this.placeholder?.length ? this.placeholder : ('shared.selectionner' | translate)}}
            </span>
            <span class="option pointer fullW" (click)="select(op)" *ngFor="let op of options;"
                cdkMenuItem>{{op[labelIndex] | translate}}</span>
        </div>
    </ng-template>
    <syn-form-errors [field]="field" [messageGroupKey]="messageGroupKey" [globalErrors]="globalErrors">
    </syn-form-errors>
</div>