<san-modal [header]="'menu.profil.changerMail' | translate" (closeModal)="close()">
  <div content>
    <form [formGroup]="emailForm">
      <syn-information message="shared.logoutMessage"></syn-information>
      <p>
        <syn-input [required]="true" [maxLength]="150" type="email" label="{{'core.userProfile.newEmail' | translate}}"
          formControlName="newEmail">
        </syn-input>
      </p>
      <p>
        <syn-input [required]="true" [maxLength]="150" type="email"
          label="{{'core.userProfile.confirmEmail' | translate}}" [globalErrors]="FormErrors.NOT_SAME"
          formControlName="cnfEmail">
        </syn-input>
      </p>
      <p>
        <syn-input [required]="true" [maxLength]="50" type="password"
          label="{{'core.userProfile.motdepasse' | translate}}" formControlName="password">
        </syn-input>
      </p>
    </form>
  </div>
  <div fxLayout="row" fxLayoutAlign="end center" footer>
    <syn-button color="warn" [label]="'shared.annuler' | translate" (onclick)="close()"></syn-button>
    <syn-button color="primary" [label]="'shared.valider' | translate" [disabled]="!emailForm.valid"
      (onclick)="apply()"></syn-button>
  </div>
</san-modal>