import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from "@angular/material/snack-bar";
import { ResponseCode } from "../models/dto/response.dto";
import { SnackBarParam } from '../models/snackBar.model';
import { SynSnackBarComponent } from './../components/syn-snack-bar/syn-snack-bar.component';
import { SnackBarType } from './../models/snackBar.model';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})
export class SynSnackBarService {

    private defaultDuration = 2500;

    constructor(private snackBar: MatSnackBar, private langueService: LanguageService) {
    }

    getPanelClass(type?: SnackBarType): string {
        let classe = 'success-snack';
        switch (type) {
            case SnackBarType.INFO:
                classe = 'info-snack';
                break;
            case SnackBarType.ERROR:
                classe = 'error-snack';
                break;
            case SnackBarType.WARNING:
                classe = 'warn-snack';
                break;
            default:
                break;
        }
        return classe;
    }

    loadConfig(data: SnackBarParam, conf?: MatSnackBarConfig<SnackBarParam>): MatSnackBarConfig<SnackBarParam> {
        const duration = data.type === SnackBarType.ERROR ? this.defaultDuration * 2 : this.defaultDuration;
        return {
            duration: data.actionLabel ? undefined : (conf?.duration ?? duration),
            horizontalPosition: conf?.horizontalPosition ?? 'right',
            verticalPosition: conf?.verticalPosition ?? 'bottom',
            panelClass: this.getPanelClass(data.type)
        };
    }

    open(data: SnackBarParam, conf?: MatSnackBarConfig<SnackBarParam>): MatSnackBarRef<SynSnackBarComponent> {
        return this.snackBar.openFromComponent(SynSnackBarComponent, Object.assign({ data: data, }, this.loadConfig(data, conf)));
    }

    getResponseSMS(code: ResponseCode, message?: string, showSuccess?: boolean, entity: string = 'Ressource'): { sms: string, type: SnackBarType } {
        let sms = '';
        let type = SnackBarType.ERROR;
        switch (code) {
            case ResponseCode.ACTION_SUCCESS:
                type = SnackBarType.SUCCESS;
                sms = showSuccess ? this.langueService.iTranslate(`core.error.${code}`) : '';
                break;
            case ResponseCode.ENTITY_EXISTS:
            case ResponseCode.ENTITY_IS_DISABLED:
            case ResponseCode.ENTITY_IS_LOCKED:
            case ResponseCode.ENTITY_IS_NOT_AUTHORISED:
            case ResponseCode.ENTITY_IS_NOT_VALID:
            case ResponseCode.ENTITY_IS_NOT_VERIFIED:
            case ResponseCode.ENTITY_NOT_FOUND:
                type = SnackBarType.WARNING;
                sms = this.langueService.iTranslate(`core.error.${code}`, { entity: this.langueService.iTranslate(entity) });
                break;
            case ResponseCode.ACTION_IS_NOT_AUTHORISED:
            case ResponseCode.ACTION_BAD_REQUEST:
            case ResponseCode.ACTION_NEED_CHANGES:
            case ResponseCode.ACTION_FAILED:
                sms = this.langueService.iTranslate(`core.error.${code}`);
                break;
            default:
                sms = message ?? this.langueService.iTranslate(`core.error.${ResponseCode.UNKWONED_ERROR}`);
                break;
        }
        return { sms, type };
    }

    handleResponse(code: ResponseCode, message?: string, showSuccess?: boolean, entity: string = 'Ressource', actionLabel?: string, actionFn?: () => void) {
        const { sms, type } = this.getResponseSMS(code, message, showSuccess, entity);
        if (sms.length) {
            this.open({ content: sms, type: type, actionFn: actionFn, actionLabel: actionLabel });
        }
    }

}