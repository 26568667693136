<div *ngIf="options?.length" [fxLayout]="flexDirection" [fxLayoutAlign]="flexAlign" class="fullW syn-radio"
    (mouseleave)="onBlur()" [class.disabled]="readonly">
    <input [hidden]="true" [id]="idControl" [name]="idControl" (ngModelChange)="value = $event" [(ngModel)]="value"
        [disabled]="readonly" />
    <div *ngFor="let op of options" class="syn-radio-option" (click)="select(op)">
        <input [hidden]="true" [id]="idControl+op[valueIndex]" type="radio" [name]="idControl"
            [checked]="isChecked(op)" />
        <span>{{op[labelIndex] | translate}}</span>
    </div>
    <syn-form-errors [field]="field" [messageGroupKey]="messageGroupKey" [globalErrors]="globalErrors">
    </syn-form-errors>
</div>