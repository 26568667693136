<div class="tree-nodes">
    <cdk-tree [class.disabled]="disabled" [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- This is the tree node template for leaf nodes -->
        <cdk-nested-tree-node *cdkTreeNodeDef="let node">
            <div fxLayout="row" fxLayoutAlign="start center" [style.padding-left.px]="node.level*30">
                <syn-selection *ngIf="withCheckBox" idControl="tree-node-{{node.id}}" [value]="node.checked"
                    (valueChange)="leafItemSelectionToggle(node,$event)" labelPosition="after"
                    [label]="node.label | translate" [readonly]="node.disabled">
                </syn-selection>
                <ng-container *ngIf="!withCheckBox">{{node.label | translate}}</ng-container>
            </div>
        </cdk-nested-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: treeService.hasChild">
            <div fxLayout="row" fxLayoutAlign="start center" [style.padding-left.px]="node.level*20">
                <syn-icon cdkTreeNodeToggle [attr.aria-label]="node.label + ' ' + node.id"
                    [name]="treeControl.isExpanded(node) ? IconName.ANGLE_DOWN : IconName.ANGLE_RIGHT" size="sm"
                    classes="expand"></syn-icon>
                <syn-selection *ngIf="withCheckBox" [readonly]="node.disabled" idControl="tree-node-{{node.id}}"
                    [value]="node.checked || descendantsAllSelected(node)" labelPosition="after"
                    [label]="node.label | translate" (valueChange)="itemSelectionToggle(node,$event)"
                    [indeterminate]="descendantsPartiallySelected(node)"></syn-selection>
                <ng-container *ngIf="!withCheckBox">{{node.label | translate}}</ng-container>
            </div>
            <div [hidden]="!treeControl.isExpanded(node)" [class.childNodes]="treeControl.isExpanded(node)">
                <ng-container cdkTreeNodeOutlet></ng-container>
            </div>
        </cdk-nested-tree-node>
    </cdk-tree>
</div>