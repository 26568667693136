import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { IconName } from './../../icon/icon.model';

@Component({
    selector: 'syn-tab',
    templateUrl: './tab.component.html'
})
export class TabComponent {
    @Input() label: string = 'Tab';
    @Input() icon: IconName;
    @Input() disabled: boolean;
    @ViewChild('template', { static: true }) template: TemplateRef<any>;
}
