<div class="syn-modal">
    <div *ngIf="header?.length" class="modal-header" fxLayout="row" fxLayoutAlign="start center">
        <label>{{header}}</label>
        <div class="app-spacer"></div>
        <span *ngIf="closable" (click)="closeModal.next()" class="close">&times;</span>
    </div>
    <div class="modal-body">
        <ng-content select="[content]"></ng-content>
    </div>
    <div *ngIf="hasFooter" class="modal-footer">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>