import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private route: ActivatedRoute, private router: Router) {}

  navigate(url: string, extras?: NavigationExtras) {
    this.router.navigateByUrl(url, extras);
  }

  goHome() {
    this.router.navigateByUrl('/');
  }

  readQueryParam(key: string) {
    return this.route.snapshot.queryParamMap.get(key);
  }

  readPathParam(key: string) {
    return this.route.snapshot.paramMap.get(key);
  }
}
