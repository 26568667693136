import { Pipe, PipeTransform } from "@angular/core";
import { SnackBarType } from "../models/snackBar.model";
import { IconName } from './../components/icon/icon.model';

@Pipe({ name: 'snackBarIcon' })
export class SnackBarIconPipe implements PipeTransform {
    transform(type?: SnackBarType): IconName {
        let icon = IconName.CHECK_CIRCLE;
        switch (type) {
            case SnackBarType.INFO:
                icon = IconName.INFO;
                break;
            case SnackBarType.ERROR:
                icon = IconName.CANCEL;
                break;
            case SnackBarType.WARNING:
                icon = IconName.WARNING;
                break;
            default:
                break;
        }
        return icon;
    }
}