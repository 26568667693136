import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard extends KeycloakAuthGuard {

    constructor(router: Router, protected keycloak: KeycloakService) {
        super(router, keycloak);
    }

    isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise((resolve, reject) => {
            if (!this.authenticated) {
                this.keycloakAngular.login().catch(e => console.error(e));
                return reject(false);
            }

            const requiredRoles: string[] = route.data['roles'];
            if (!requiredRoles || requiredRoles.length === 0) {
                return resolve(true);
            } else {
                if (!this.roles || this.roles.length === 0) {
                    resolve(false);
                }
                resolve(requiredRoles.every(role => this.roles.includes(role)));
            }
        });
    }
}