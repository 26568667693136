import { IconName } from './../icon/icon.model';
import { ArrayDataSource } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TreeData } from './../../models/interfaces/tree.interface';
import { TreeService } from './../../services/tree.service';

@Component({
  selector: 'syn-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss']
})
export class TreeComponent implements OnChanges {

  @Input() treeData: TreeData[] = [];
  @Input() checkedData: TreeData[] = [];
  @Input() disabled: boolean = false;
  @Input() withCheckBox: boolean = true;
  @Input() expandOnInit: boolean;
  @Output() nodeChecked: EventEmitter<TreeData> = new EventEmitter<TreeData>();

  IconName: typeof IconName = IconName;

  selectedParent: TreeData = null;

  treeControl: NestedTreeControl<TreeData> = new NestedTreeControl<TreeData>(node => node.children ?? []);

  dataSource: ArrayDataSource<TreeData>;

  constructor(public treeService: TreeService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['treeData']) {
        this.treeData = TreeService.updateLevel(this.treeData);
        this.dataSource = new ArrayDataSource(this.treeData);
        this.treeControl.dataNodes = this.treeData;
        this.expandNodes();
      }

      if (changes['checkedData']) {
        this.updateCheckedNodes();
      }
    }
  }

  /**
   * Expand tree nodes
   */
  expandNodes() {
    if (this.expandOnInit) {
      this.treeControl.expandAll();
    } else {
      this.dataNodes.filter(t => t.expand).forEach(n => this.treeControl.expand(n));
    }
  }

  private unCheckAllNodes() {
    for (const n of this.dataNodes) {
      n.checked = false;
      TreeService.updateChildrenState(this.treeControl.getDescendants(n), false);
    }
  }

  /**
   * Update checked nodes
   */
  private updateCheckedNodes() {
    this.unCheckAllNodes();
    const nodes = (this.checkedData ?? []).map(n => n.id);
    if (nodes.length) {
      for (const n of this.dataNodes) {
        n.checked = nodes.includes(n.id);
        this.treeControl.getDescendants(n).forEach(c => {
          c.checked = nodes.includes(c.id);
          this.checkAllParentsSelection(c);
        });
        this.checkAllParentsSelection(n);
      }
    }
  }

  /**
   * Whether all the descendants of the node are selected.
   * @param node 
   * @returns 
   */
  descendantsAllSelected(node: TreeData): boolean {
    return this.treeControl.getDescendants(node)?.every((child: TreeData) => child.checked);
  }

  /**
   * Whether part of the descendants are selected
   * @param node 
   * @returns 
   */
  descendantsPartiallySelected(node: TreeData): boolean {
    const result = this.treeControl.getDescendants(node)?.some((child: TreeData) => child.checked);
    return result && !this.descendantsAllSelected(node);
  }

  /**
   * Select/Deselect node
   * @param node 
   */
  itemSelectionToggle(node: TreeData, state: boolean): void {
    node.checked = state;
    TreeService.updateChildrenState(this.treeControl.getDescendants(node), node.checked);
    this.checkAllParentsSelection(node);
    this.nodeChecked.next(node);
  }

  /**
   * Select/Deselect leaf node
   * @param node 
   */
  leafItemSelectionToggle(node: TreeData, state: boolean): void {
    node.checked = state;
    this.checkAllParentsSelection(node);
    this.nodeChecked.next(node);
  }

  private get dataNodes(): TreeData[] {
    return this.treeControl.dataNodes ?? [];
  }

  /**
   * Checks all the parents when a leaf node is selected/unselected
   * @param node 
   */
  private checkAllParentsSelection(node: TreeData): void {
    let parent: TreeData = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  /**
   * Check root node checked state and change it accordingly
   * @param node 
   */
  private checkRootNodeSelection(node: TreeData): void {
    const descAllSelected = this.treeControl.getDescendants(node)?.every((child: TreeData) => child.checked);
    if (node.checked && !descAllSelected) {
      node.checked = false;
    } else if (!node.checked && descAllSelected) {
      node.checked = true;
    }
  }

  /**
   * Get the parent node of a node
   * @param node 
   * @returns 
   */
  private getParentNode(node: TreeData): TreeData {
    return TreeService.findParent(node, this.dataNodes, null);
  }

  get checkedNodes(): TreeData[] {
    const nodes: TreeData[] = [];
    for (const node of this.dataNodes) {
      if (node.checked) {
        nodes.push(node);
      }
      nodes.push(...this.treeControl.getDescendants(node).filter(n => n.checked));
    }
    return nodes;
  }

}
