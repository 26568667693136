import { AfterViewInit, Component, ElementRef, HostListener, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControl } from '../utils/form-control-base.component';
import { IconName } from './../icon/icon.model';

@Component({
  selector: 'syn-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ]
})
export class SelectComponent extends BaseFormControl<any | string | number> implements OnInit, OnChanges, AfterViewInit {
  @Input() set labelField(field: string) {
    this.labelIndex = field?.length ? field : 'label';
  }
  @Input() set valueField(field: string) {
    this.valueIndex = field?.length ? field : 'value';
  }
  @Input() options: any[] = [];
  @Input() globalErrors: string[] | string;
  @Input() manageObject: boolean = false;
  @Input() showPlaceholder: boolean = true;
  @ViewChild('wrapper') wrapper: ElementRef;
  IconName: typeof IconName = IconName;
  selected: any;
  width: number;
  labelIndex: string = 'label';
  valueIndex: string = 'value';

  constructor(public override injector: Injector) {
    super(injector);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      super.ngOnChanges(changes);
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.computeMenuWidth();
  }

  override writeValue(value: any | string | number): void {
    this.selected = !this.manageObject ? this.options?.find(o => o[this.valueIndex] === value) : value;
    this.value = this.selectedValue;
  }

  computeMenuWidth(): void {
    this.width = this.wrapper.nativeElement.getBoundingClientRect().width;
  }

  get selectedLabel() {
    return this.selected && this.selected[this.valueIndex] ? this.selected[this.labelIndex] : '';
  }

  get selectedValue() {
    return !this.manageObject && this.selected ? this.selected[this.valueIndex] : this.selected;
  }

  select(option: any) {
    this.selected = option;
    this.value = this.selectedValue;
    this.onChangeValue();
  }

  @HostListener('window:resize')
  public onWinResize(): void {
    this.wrapper.nativeElement.click();
  }

}
