import { AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { emailValidator, numberValidator, telValidator } from '../utils/custom.validators';
import { BaseFormControl } from '../utils/form-control-base.component';

@Component({
  selector: 'syn-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent extends BaseFormControl<string | number> implements OnInit, OnChanges, AfterViewInit {

  @Input() clearable: boolean;
  @Input() pattern: RegExp;
  @Input() maxLength: number;
  @Input() minLength: number;
  @Input() cols: number = 10;
  @Input() rows: number = 5;
  @Input() type: 'text' | 'number' | 'tel' | 'email' | 'password' | 'textarea' = 'text';
  @Input() globalErrors: string[] | string;

  constructor(public override injector: Injector) {
    super(injector);
  }

  private baseValidators() {
    switch (this.type) {
      case 'number':
        this.field?.addValidators(numberValidator());
        break;
      case 'tel':
        this.minLength = this.minLength ?? 10;
        this.field?.addValidators(telValidator());
        break;
      case 'email':
        this.field?.addValidators(emailValidator());
        break;
      default:
        break;
    }
  }

  private updateControlStates() {
    if (this.field) {
      if (this.minLength) {
        this.field.addValidators(Validators.minLength(this.minLength));
      } else {
        this.field.removeValidators(Validators.minLength(0));
      }

      if (this.maxLength) {
        this.field.addValidators(Validators.maxLength(this.maxLength));
      } else {
        this.field.removeValidators(Validators.maxLength(0));
      }

      if (this.pattern) {
        this.field.addValidators(Validators.pattern(this.pattern));
      } else {
        this.field.removeValidators(Validators.pattern(null));
      }
    }
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      super.ngOnChanges(changes);
      this.updateControlStates();
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.baseValidators();
    this.updateControlStates();
  }

  override writeValue(value: string | number): void {
    this.value = value;
  }

  get inputType() {
    return ['number', 'tel'].includes(this.type) ? 'text' : this.type;
  }

  get textarea() {
    return this.type === 'textarea';
  }

}
