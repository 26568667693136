<div class="syn-card" [style.width]="width">
    <div *ngIf="header?.length" class="card-header" fxLayout="row" fxLayoutAlign="start center">
        <syn-icon *ngIf="icon" [clickable]="false" [name]="icon"></syn-icon> <span>{{header}}</span>
    </div>
    <div class="card-body">
        <ng-content select="[content]"></ng-content>
    </div>
    <div *ngIf="hasFooter" class="card-footer" fxLayout="row" fxLayoutAlign="end center">
        <ng-content select="[footer]"></ng-content>
    </div>
</div>