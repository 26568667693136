import { Component, Injector, Input, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControl } from '../utils/form-control-base.component';

@Component({
  selector: 'syn-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleComponent),
      multi: true
    }
  ]
})
export class SlideToggleComponent extends BaseFormControl<boolean> implements OnInit {

  @Input() labelPosition: 'after' | 'before' = 'before';

  constructor(public override injector: Injector) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override writeValue(value: boolean): void {
    this.value = value;
  }

  get labelBefore() {
    return this.label?.length && this.labelPosition === 'before';
  }

  get labelAfter() {
    return this.label?.length && this.labelPosition === 'after';
  }
}
