import { Injectable } from '@angular/core';
import { FileUploadComponent } from '../components/file-upload/file-upload.component';
import { DialogData } from '../models/dialog.model';
import { UploadRequest } from './../models/interfaces/file.request';
import { ApplicationService } from './application.service';
import { SynDialogService } from './dialog.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor(private dialogService: SynDialogService) { }

    /**
     * Operation de transformation du buffer en base64
     * @param buffer 
     * @returns 
     */
    private bufferToBase64(buffer: any): string {
        if (buffer?.data) {
            const data = new Uint8Array(buffer.data);
            return ApplicationService.encodeBase64(data.reduce((elt, byte) => elt + String.fromCharCode(byte), ''));
        }
        return null;
    }

    /**
     * Decode le buffer en base64 pour affichage
     * @param buffer 
     * @returns 
     */
    decodeFile(buffer: Buffer): string {
        const b64 = this.bufferToBase64(buffer);
        return b64 ? `data:image/png;base64,${b64}` : null;
    }

    openLoader(data: UploadRequest) {
        return this.dialogService.dialog.open<DialogData>(FileUploadComponent, {
            data,
            width: '800px',
            disableClose: false,
            closeOnNavigation: false,
            hasBackdrop: true
        });
    }

    public static convertSize(bytes: number): string {
        return (bytes / 1048576).toFixed(2);
    }
}