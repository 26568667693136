import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MenuItem } from './../../models/interfaces/menu.interface';

@Component({
  selector: 'syn-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @ViewChild('mainMenu', { static: true }) mainMenu: TemplateRef<any>;
  @Input() menuItems: MenuItem[] = [];
  @Output() triggerMenu: EventEmitter<MenuItem> = new EventEmitter<MenuItem>();

  clickOnMenu(m: MenuItem) {
    if (m?.click) {
      m.click();
    }
    this.triggerMenu.next(m);
  }

  isVisible(m: MenuItem): boolean {
    return !Object.keys(m).includes('visible') || !!m.visible;
  }

}
