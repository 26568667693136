import * as _ from "lodash";

export class ObjectUtils {
    public static formatTel(tel: string = '', portable: string = ''): string {
        return [tel, portable].filter(s => s?.length).join(' / ');
    }

    public static truncate(text: string, size: number = 10): string {
        if (text?.length > size) {
            return `${text.substring(0, size)}...`;
        }
        return text;
    }

    static generateArray(start: number = 0, end: number = 10, step: number = 1): number[] {
        return _.range(start, end, step);
    }
}