import { IconName } from './../icon/icon.model';
import { Component, Injector, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { AppconfService } from 'src/app/core/services/app-conf.service';
import { UploadService } from '../../api/upload.service';
import { ResponseCode } from '../../models/dto/response.dto';
import { FileSizeOption, UploadRequest } from '../../models/interfaces/file.request';
import { FileService } from '../../services/file.service';
import { LanguageService } from '../../services/language.service';
import { BaseDialogComponent } from '../utils/base-dialog.component';
import { AppConf } from './../../../core/interfaces/app-conf.interface';
import { FileItem } from './../../models/interfaces/file.request';
import { SynSnackBarService } from './../../services/snackbar.service';

@Component({
  selector: 'syn-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent extends BaseDialogComponent implements OnInit {

  options: UploadRequest = this.data as any;
  conf: AppConf = this.appConfService.getAppConf();
  extensions: string = this.conf.file.imageFormat;
  multiple: boolean = false;
  maxSize: number = this.conf.file.size; // 1Mo
  accept: string = 'image/*';
  loading: boolean = false;
  choosingError: boolean = false;
  currentFile: FileItem;
  IconName: typeof IconName = IconName;
  files: FileItem[] = [];

  constructor(protected override injector: Injector,
    private languageService: LanguageService,
    private uploadService: UploadService,
    private snackBarService: SynSnackBarService,
    private appConfService: AppconfService) {
    super(injector);
  }

  ngOnInit() {
    this.options.maxFiles = this.options.maxFiles ?? this.conf.file.maxFiles;
    this.multiple = this.options.maxFiles > 1;
    if (this.options.isImage === false) {
      this.extensions = this.conf.file.docFormat;
      this.accept = '*/*';
    }

    switch (this.options?.sizeOption) {
      case FileSizeOption.MEDIUM_SIZE:
        this.maxSize *= 5; // 5Mo
        break;
      case FileSizeOption.MAX_SIZE:
        this.maxSize *= 10; // 10Mo
        break;
      default:
        break;
    }
  }

  handleFiles(event: any) {
    const files = event.target?.files as File[];

    // On supprime des fichiers anciens pour remplacer les nouveaux
    if (this.files.length === this.options.maxFiles) {
      this.files.splice(0, files.length);
    }

    for (let i = 0; i < files.length && this.files.length < this.options.maxFiles; i++) {
      const file = files[i];
      let message = '';
      const fileExt = file.name.split('.').pop().toLowerCase();
      const extOk = (',' + this.extensions + ',').indexOf(',' + fileExt + ',') >= 0;
      if (!extOk) { // extension KO
        message = this.languageService.iTranslate('fichier.incorrectFileFormat', { fExt: fileExt });
      } else if (file.size > this.maxSize) { // trop lourd
        message = this.languageService.iTranslate('fichier.fileTooLourd', { fSize: FileService.convertSize(file.size) });
      }
      this.files.push({ id: new Date().getTime() + i, file, url: URL.createObjectURL(file), message: message });
    }
    this.choosingError = this.files.some(f => f.message?.length > 0);
    this.currentFile = this.files[0];
  }

  deleteFile(index: number) {
    this.files.splice(index, 1);
    this.choosingError = this.files.some(f => f.message?.length > 0);
    this.currentFile = this.files[0];
  }

  upload() {
    if (this.files.length) {
      this.loading = true;
      const data = new FormData();
      data.append('ressourceId', this.options.ressourceId.toString());
      for (const f of this.files) {
        data.append('fichiers', f.file, f.file.name);
      }
      this.uploadService.upload(this.options.destination, data).pipe(take(1)).subscribe(res => {
        this.loading = false;
        this.snackBarService.handleResponse(res.code, res.message ? this.languageService.iTranslate(`fichier.${res.message}`) : null, true);
        if (this.options?.closeAfterUpload && res.code === ResponseCode.ACTION_SUCCESS && res.data) {
          this.close({ content: res.data.files });
        }
      });
    }
  }

}
