import { Component, Input } from '@angular/core';
import { Size } from 'ngx-spinner';
import { LoaderService } from './../../services/loader.service';

@Component({
  selector: 'syn-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent {

  @Input() name: string = this.loaderService.spinnerName;
  @Input() fullscreen: boolean = false;
  @Input() type: string = 'line-scale';
  @Input() message: string;
  @Input() background: string = 'rgba(0,0,0, .3)';
  @Input() size: Size = 'small';

  @Input() set loading(state: boolean) {
    this.loaderService.toggleSpinner(state, this.name);
  }

  constructor(private loaderService: LoaderService) { }

}
