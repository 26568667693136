import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Subject, debounceTime } from 'rxjs';
import { ReactiveService } from 'src/app/shared/services/reactive.service';
import { Page, SortDirection } from './../../models/filter.interface';
import { ACTION_COL, Column, ColumnType, ExportType, SortOrder, TableOptions } from './../../models/interfaces/datatable.interface';
import { MenuItem } from './../../models/interfaces/menu.interface';
import { IconName } from './../icon/icon.model';

@Component({
  selector: 'syn-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent<T> implements OnInit, OnChanges {

  @Input() set data(data: T[]) {
    this.rows = data ?? [];
  }
  @Input() loading: boolean;
  @Input() showTitle: boolean = true;
  @Input() columns: Column[] = [];
  @Input() nbItems: number;
  @Input() options: TableOptions;
  @Input() selectedRow: T;
  @Input() traductionGroup: string;
  @Input() globalMenu: MenuItem[] = [];
  @Output() pageChange: EventEmitter<Page> = new EventEmitter<Page>();
  @Output() rowClick: EventEmitter<T> = new EventEmitter<T>();
  @Output() eventAdd: EventEmitter<void> = new EventEmitter<void>();
  @Output() eventDelete: EventEmitter<T> = new EventEmitter<T>();
  @Output() eventEdit: EventEmitter<T> = new EventEmitter<T>();
  @Output() eventDuplicate: EventEmitter<T> = new EventEmitter<T>();
  @Output() searching: EventEmitter<string> = new EventEmitter<string>();
  @Output() exporting: EventEmitter<ExportType> = new EventEmitter<ExportType>();
  @Output() sortByChange: EventEmitter<SortOrder> = new EventEmitter<SortOrder>();
  @Output() selectedRows: EventEmitter<T[]> = new EventEmitter<T[]>();

  taping: Subject<string> = new Subject<string>();
  selecting: Subject<T[]> = new Subject<T[]>();
  exportMenu: MenuItem[] = [];
  menu: MenuItem[] = [];
  term: string;
  dataColumns: Column[];
  ColumnType = ColumnType;
  initialization = true;
  showMenu = false;
  sortBy: SortOrder;
  rows: T[];
  SortDirection = SortDirection;
  allChecked: boolean = false;
  IconName: typeof IconName = IconName;

  constructor(private reactiveService: ReactiveService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['columns'] || changes['options'] || changes['menu']) {
        if (changes['columns']) {
          this.dataColumns = _.cloneDeep(this.columns);
        }
        this.initColumns();
      }
    }
  }

  private initColumns() {
    this.columns = this.columns?.filter(c => ACTION_COL.field !== c.field);
    this.showMenu = !!(this.options.editMenu || this.options.deleteMenu || this.options.duplicateMenu || this.globalMenu.length || this.options?.showCheckboxes);
    this.menu = [];
    if (this.showMenu) {
      this.columns.unshift(ACTION_COL);
      if (this.options.duplicateMenu) {
        this.menu.push({
          id: this.menu.length,
          label: 'shared.dupliquer',
          visible: true,
          click: () => this.newElement(this.selectedRow)
        });
      }
      if (this.options.editMenu) {
        this.menu.push({
          id: this.menu.length,
          label: 'shared.modifier',
          visible: true,
          click: () => this.eventEdit.next(this.selectedRow)
        });
      }
      if (this.options.deleteMenu) {
        this.menu.push({
          id: this.menu.length,
          label: 'shared.supprimer',
          visible: true,
          click: () => this.eventDelete.next(this.selectedRow)
        });
      }
    }
    if (this.options.exportMenu) {
      this.exportMenu = [
        {
          id: 1, label: 'shared.exporterPDF', visible: true,
          click: () => this.exporting.next(ExportType.PDF)
        },
        {
          id: 2, label: 'shared.exporterCSV', visible: true,
          click: () => this.exporting.next(ExportType.CSV)
        }
      ];
    }
  }

  ngOnInit(): void {
    this.taping.pipe(debounceTime(500)).subscribe(term => this.searching.next(term));
    this.selecting.pipe(debounceTime(500)).subscribe(rows => this.selectedRows.next(rows));
  }

  toggleCheckboxes(state: boolean) {
    this.allChecked = state;
    this.rows.forEach((r: any) => r.checked = state);
    this.selecting.next(state ? this.rows : []);
  }

  toggleCheckbox(i: number, state: boolean) {
    (this.rows[i] as any).checked = state;
    const data = this.rows.filter((r: any) => r.checked);
    this.allChecked = data.length === this.rows.length;
    this.selecting.next(data);
  }

  getColValue(col: Column, item: any) {
    return col.displayFn ? col.displayFn(item) : item[col.field];
  }

  getBoolIcon(col: Column, item: any) {
    return item[col.field] ? this.IconName.CHECK_CIRCLE : this.IconName.CANCEL_CIRCLE;
  }

  getColTitle(col: Column, item: T) {
    return col.titleFn ? col.titleFn(item) : '';
  }

  getDirection(col: Column): SortDirection {
    if (this.sortBy) {
      return this.sortBy.field === col.field ? this.sortBy.direction : null;
    }
    return col.defaultSort ? SortDirection.ASC : null;
  }

  getIcon(col: Column): string {
    let direction = col.defaultSort ? SortDirection.ASC : null;
    if (this.sortBy) {
      direction = this.sortBy.field === col.field ? this.sortBy.direction : direction;
    }
    if (direction === SortDirection.DESC) {
      return this.IconName.SORT_DOWN;
    }
    return direction === SortDirection.ASC ? this.IconName.SORT_UP : this.IconName.SORT;
  }

  sort(col: Column) {
    this.sortBy = {
      field: col.field,
      direction: this.getDirection(col) === SortDirection.DESC ? SortDirection.ASC : SortDirection.DESC
    };
    this.sortByChange.next(this.sortBy);
  }

  get displayedColumns(): string[] {
    return this.columns.map(c => c.field);
  }

  newElement(item?: T) {
    if (item) {
      this.eventDuplicate.next(item);
    } else {
      this.eventAdd.next();
    }
    this.selectedRow = null;
  }

  rowClicked(item: T) {
    this.selectedRow = item;
    this.rowClick.next(item);
  }

  rowDblClicked(item: T) {
    this.eventEdit.next(item);
  }

  search(value: string) {
    if (!this.initialization) {
      this.taping.next(value);
    }
    this.initialization = false;
  }

}
