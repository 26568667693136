import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { ResponseCode, SantynelleResponse } from 'src/app/shared/models/dto/response.dto';
import { FileDestination } from 'src/app/shared/models/interfaces/file.request';
import { DocumentFileResponse, FileResponse, ImageFileResponse } from './../models/interfaces/file.interface';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    constructor(private userService: UserService) {
    }

    upload(destination: FileDestination, data: FormData): Observable<SantynelleResponse<FileResponse<ImageFileResponse | DocumentFileResponse>>> {
        let ws = of({ data: null, code: ResponseCode.ACTION_FAILED, date: new Date(), message: null, size: 0 });
        switch (destination) {
            case FileDestination.USER:
                ws = this.userService.updatePhoto(data);
                break;
            default:
                break;
        }
        return ws;
    }

}