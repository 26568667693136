import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppConf } from '../interfaces/app-conf.interface';
import { environment } from './../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AppconfService {

    private appConf: AppConf;

    constructor(protected http: HttpClient) {
    }

    public getAppConf(): AppConf {
        return this.appConf;
    }

    public init(): Observable<AppConf> {
        return this.http.get<AppConf>('./app.conf.json').pipe(take(1), tap((appConf: AppConf) => {
            this.appConf = _.cloneDeep(appConf);
            let url = appConf.apiUrl?.replace(/^https?:\/\//, '')?.split('/')[0];
            if (!url?.length) {
                url = 'localhost';
            }
            this.appConf.comUrl = `${environment.wss}://${url}:${this.appConf.comPort}`;
        }));
    }
}
