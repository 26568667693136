export type IconSize = '2xs' | 'xs' | 'sm' | 'lg' | 'xl' | '2xl';

export type IconStyle = 'solid' | 'regular' | 'brands';

export enum IconName {
    PLUS = 'plus',
    DOWNLOAD = 'download',
    UP = 'caret-up',
    DOWN = 'caret-down',
    ARROW_UP = 'arrow-up',
    ARROW_RIGHT = 'arrow-right',
    ARROW_DOWN = 'arrow-down',
    ARROW_LEFT = 'arrow-left',
    ANGLE_UP = 'angle-up',
    ANGLE_RIGHT = 'angle-right',
    ANGLES_RIGHT = 'angles-right',
    ANGLE_DOWN = 'angle-down',
    ANGLE_LEFT = 'angle-left',
    ANGLES_LEFT = 'angles-left',
    ARROW_CIRCLE_UP = 'circle-arrow-up',
    ARROW_CIRCLE_RIGHT = 'circle-arrow-right',
    ARROW_CIRCLE_DOWN = 'circle-arrow-down',
    ARROW_CIRCLE_LEFT = 'circle-arrow-left',
    CHECK = 'check',
    CHECK_CIRCLE = 'circle-check',
    CANCEL = 'xmark',
    CANCEL_CIRCLE = 'circle-xmark',
    CHEVRON_UP = 'chevron-up',
    CHEVRON_DOWN = 'chevron-down',
    CHEVRON_RIGHT = 'chevron-right',
    CHEVRON_LEFT = 'chevron-left',
    SORT = 'sort',
    SORT_DOWN = 'sort-down',
    SORT_UP = 'sort-up',
    WARNING = 'exclamation-triangle',
    INFO = 'info-circle',
    LAST = 'step-forward',
    NEXT = 'caret-right',
    PREVIOUS = 'caret-left',
    FIRST = 'step-backward',
    FULLSCREEN = 'expand',
    CHANGE_PHOTO = 'camera-rotate',
    REMOVE_FILE = 'file-circle-xmark',
    MENU = 'ellipsis-vertical',
    MENU_BAR = 'bars',
    FILE = 'file-lines',
    USER = 'user',
    USER_GROUP = 'user-group',
    USER_TIE = 'user-tie',
    ADDRESS = 'address-card',
    LOCK = 'lock',
    CONFIG = 'gear',
    SHIELD = 'shield',
    CALENDAR = 'calendar-days',
    CALENDAR_DAY = 'calendar-day',
    CALENDAR_WEEK = 'calendar-week',
    DELETE = 'trash-can',
    NOTIFICATION = 'bell',
    QUESTION = 'circle-question',
    EMAIL = 'envelope',
    BALANCE = 'scale-balanced',
    HOME = 'house',
    DOUBLE_BACK = "backward",
    DOUBLE_NEXT = "forward",
}