export enum DroitModule {
    USER = 'USER',
    GROUPE = 'GROUPE',
    DROITS = 'DROITS',
    MATERIEL = 'MATERIEL',
    BATIMENT = 'BATIMENT',
    AGENDA = 'AGENDA',
    ANNUAIRE = 'ANNUAIRE',
    RDV = 'RDV',
    CONSULTATION = 'CONSULTATION',
    HOSPITALISATION = 'HOSPITALISATION',
    ANALYSE = 'ANALYSE',
    RESULTAT = 'RESULTAT',
    FACTURATION = 'FACTURATION'
}

export enum DroitCode {
    APPLICATION_ADMIN = 'APPLICATION_ADMIN',
    USER_AJOUTER = 'USER_AJOUTER',
    USER_LIRE = 'USER_LIRE',
    USER_SUPPRIMER = 'USER_SUPPRIMER',
    USER_ADMIN = 'USER_ADMIN',
    USER_MODIFIER = 'USER_MODIFIER',
    GROUPE_AJOUTER = 'GROUPE_AJOUTER',
    GROUPE_MODIFIER = 'GROUPE_MODIFIER',
    GROUPE_SUPPRIMER = 'GROUPE_SUPPRIMER',
    GROUPE_LIRE = 'GROUPE_LIRE',
    GROUPE_ADMIN = 'GROUPE_ADMIN',
    DROITS_ADMIN = 'DROITS_ADMIN',
    USER_EMAIL_MODIFIER = 'USER_EMAIL_MODIFIER',
    USER_PASSWORD_MODIFIER = 'USER_PASSWORD_MODIFIER',
    BATIMENT_LIRE = 'BATIMENT_LIRE',
    BATIMENT_AJOUTER = 'BATIMENT_AJOUTER',
    BATIMENT_MODIFIER = 'BATIMENT_MODIFIER',
    BATIMENT_SUPPRIMER = 'BATIMENT_SUPPRIMER',
    MATERIEL_LIRE = 'MATERIEL_LIRE',
    MATERIEL_AJOUTER = 'MATERIEL_AJOUTER',
    MATERIEL_MODIFIER = 'MATERIEL_MODIFIER',
    MATERIEL_SUPPRIMER = 'MATERIEL_SUPPRIMER',
    BATIMENT_ADMIN = 'BATIMENT_ADMIN',
    MATERIEL_ADMIN = 'MATERIEL_ADMIN'
}

export interface Droit {
    id: string;
    libelle: string;
    code: string;
}