import { AfterViewInit, Component, Injector, Input, OnInit, forwardRef } from '@angular/core';
import { AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { BaseFormControl } from '../utils/form-control-base.component';
import { FormatDate } from './../../models/enum/date-format.enum';
import { DateService } from './../../services/date.service';
import { LanguageService } from './../../services/language.service';
import { IconName } from './../icon/icon.model';

function dateValidator(dateValue: BehaviorSubject<string>, required: boolean = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const date = dateValue.getValue();
    if (date?.length && !control.value) {
      return { notDateTime: { value: date } };
    }
    return null;
  };
}

@Component({
  selector: 'syn-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateComponent),
      multi: true
    }
  ]
})
export class DateComponent extends BaseFormControl<Date> implements OnInit, AfterViewInit {

  @Input() dateOnly: boolean = true;
  @Input() showSeconds: boolean = false;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() globalErrors: string[] | string;
  IconCalandar = IconName.CALENDAR;
  pickerState: boolean = false;
  pickerPosition: any[] = [
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
    { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' }
  ];
  dateValue: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(public override injector: Injector, private langueService: LanguageService, private dateService: DateService) {
    super(injector);
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.field.addValidators(dateValidator(this.dateValue, this.required));
  }

  override writeValue(obj: Date): void {
    this.dateService.format(obj, this.format);
    this.value = obj;
  }

  get format() {
    if (!this.dateOnly) {
      return this.showSeconds ? FormatDate.DayMonthYearHourMinSec : FormatDate.DayMonthYearHourMin;
    }
    return FormatDate.DayMonthYear;
  }

  get maxsize(): number {
    return this.dateService.getDateSize(this.format);
  }

  computeDate(event: any) {
    const date = event.target.value;
    this.value = this.dateService.transform(date, this.format);
    this.dateValue.next(date);
    this.field.markAsTouched();
  }

  handleSelected(date: Date) {
    this.value = date;
    this.pickerState = false;
  }

}
