<div #tabGroup class="syn-tab">
    <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="displayLeft" class="btn-nav" (click)="prevTab()">
            <syn-icon [name]="IconName.CHEVRON_LEFT"></syn-icon>
        </div>
        <ng-container *ngFor="let tab of tabs;let i=index">
            <div *ngIf="isVisible(i)" fxLayout="row" fxLayoutAlign="start center" class="tab-nav"
                [class.currentNav]="isSelected(i)" (click)="select(i)" [class.disabled]="tab.disabled">
                <syn-icon *ngIf="tab.icon && width >= 550" [name]="tab.icon" [classes]="!isSelected(i) ? 'tAccent':''"
                    [clickable]="false"></syn-icon>
                <span>{{tab.label}}</span>
                <input [hidden]="true" name="nav" type="radio" class="nav-radio" id="tab{{i}}"
                    [checked]="isSelected(i)" />
            </div>
        </ng-container>
        <div *ngIf="displayRight" class="btn-nav" (click)="nextTab()">
            <syn-icon [name]="IconName.CHEVRON_RIGHT"></syn-icon>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start start" class="syn-tab-content">
        <div *ngFor="let content of tabs;let j=index;" [hidden]="!isSelected(j)" class="page"
            [class.currentTab]="isSelected(j)">
            <div class="page-contents">
                <ng-template [ngTemplateOutlet]="content.template"></ng-template>
            </div>
        </div>
    </div>
</div>