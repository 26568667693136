import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { SessionKey } from "../models/session.keys";

@Injectable({
    providedIn: 'root'
})
export class ApplicationService {

    resizingWindow: Subject<Event> = new Subject<Event>();

    public static storeSession<T>(key: SessionKey, value: T) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    public static readSession<T>(key: SessionKey): T {
        const data = sessionStorage.getItem(key);
        return data ? JSON.parse(data) : null;
    }

    public static deleteSession(key: string) {
        sessionStorage.removeItem(key);
    }

    public static encodeBase64(data: string) {
        return window.btoa(data);
    }

    public static decodeBase64(data: string) {
        return window.atob(data);
    }
}