import { Component, Input, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { ReactiveService } from 'src/app/shared/services/reactive.service';
import { UserService } from './../../../shared/api/user.service';
import { IconName } from './../../../shared/components/icon/icon.model';
import { MenuItem } from './../../../shared/models/interfaces/menu.interface';
import { SynDialogService } from './../../../shared/services/dialog.service';
import { LanguageService } from './../../../shared/services/language.service';
import { LoaderService } from './../../../shared/services/loader.service';
import { MenuService } from './../../services/menu.service';
import { ChangeEmailComponent } from './../change-email/change-email.component';
import { ChangePasswordComponent } from './../change-password/change-password.component';
import { UserProfileComponent } from './../user-profile/user-profile.component';

@Component({
  selector: 'syn-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() sidenav?: any;
  @Input() hideMenu: boolean;
  @Input() menu: MenuItem[] = [];
  title: string;
  dashboardMenu: string = MenuService.dashboard;
  url: string = this.dashboardMenu;
  IconName: typeof IconName = IconName;

  profileMenu: MenuItem[] = [
    { label: 'menu.profil.profil', click: () => this.openProfileDialog() },
    { label: 'menu.profil.changerMail', click: () => this.openEmailDialog() },
    { label: 'menu.profil.changerPass', click: () => this.openPasswordDialog() },
    { label: 'menu.profil.deconnexion', click: () => this.logout() }
  ];

  langueMenu: MenuItem[] = this.languageService.languages.map(code => ({
    label: `application.${code}`,
    icon: `./assets/images/${code}.png`,
    click: () => this.languageService.changeLanguage(code)
  }));

  constructor(
    public languageService: LanguageService,
    public userService: UserService,
    private router: Router,
    public reactiveService: ReactiveService,
    private dialogService: SynDialogService,
    private loaderService: LoaderService
  ) { }

  ngOnInit(): void {
    this.title = this.dashboardMenu;
    this.router.events.subscribe((nav: any) => this.navigationInterceptor(nav));
  }

  openProfileDialog() {
    this.dialogService.open(UserProfileComponent, { width: '700px' });
  }

  openPasswordDialog() {
    this.dialogService.open(ChangePasswordComponent);
  }

  openEmailDialog() {
    this.dialogService.open(ChangeEmailComponent);
  }

  getMenuTitle(menus: MenuItem[], url: string): string {
    let label = '';
    if (!url || url === '/' || url === this.dashboardMenu) {
      label = 'menu.tdb';
    } else {
      for (const menu of menus) {
        if (url.includes(`/${menu.link}`)) {
          return menu.label;
        } else if (menu.items?.length) {
          label = this.getMenuTitle(menu.items, url);
          if (label.length) {
            return label;
          }
        }
      }
    }
    return label;
  }

  navigationInterceptor(event: any) {
    switch (true) {
      case event instanceof NavigationStart:
        this.toggleSpinner(null, true);
        this.url = event.url;
        this.title = this.getMenuTitle(this.menu, this.url);
        break;
      case event instanceof NavigationEnd:
      case event instanceof NavigationCancel:
      case event instanceof NavigationError:
        this.toggleSpinner();
        break;
      default:
        break;
    }
  }

  logout() {
    this.toggleSpinner('application.deconnexion_encours', true);
    this.userService.logout().then(() => this.toggleSpinner());
  }

  private toggleSpinner(sms?: string, state: boolean = false) {
    this.loaderService.toggleMainLoader.next({ state: state, sms: sms });
  }
}
