<ng-template #mainMenu>
    <div *ngIf="menuItems.length" class="syn-menu" fxLayout="column" fxLayoutAlign="start start" cdkMenu>
        <ng-container *ngFor="let m of menuItems;let last = last;">
            <ng-container *ngIf="isVisible(m)">
                <ng-container *ngIf=" !m.items?.length else withItems;">
                    <div class="syn-menu-item" [class.separator]="!last && !!m.separator" (click)="clickOnMenu(m)"
                        fxLayout="row" fxLayoutAlign="start center" cdkMenuItem>
                        <img alt="Icon" *ngIf="m.icon?.length" class="btnFlag" [src]="m.icon" />
                        <span>{{m.label | translate}}</span>
                    </div>
                </ng-container>
                <ng-template #withItems>
                    <div class="syn-menu-item" [class.separator]="!last && !!m.separator" fxLayout="row"
                        fxLayoutAlign="start center" cdkMenuItem [cdkMenuTriggerFor]="menuItems.mainMenu">
                        <img alt="Icon" *ngIf="m.icon?.length" class="btnFlag" [src]="m.icon" />
                        <span>{{m.label | translate}}</span>
                    </div>
                    <syn-menu #menuItems [menuItems]="m.items"></syn-menu>
                </ng-template>
            </ng-container>
        </ng-container>
    </div>
</ng-template>