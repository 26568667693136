import { Injectable } from '@angular/core';
import { DroitModule } from './../../shared/models/dto/droit.dto';
import { MenuItem } from './../../shared/models/interfaces/menu.interface';
import { DroitService } from './../../shared/services/droit.service';

@Injectable({
    providedIn: 'root',
})
export class MenuService {

    public menus: MenuItem[] = [];
    private menuId: number = 1;
    public static dashboard: string = '/dashboard';

    constructor(private droitService: DroitService) {
        this.droitService.connectedUserDroits.subscribe(() => this.initMenu());
    }

    initMenu() {
        this.menus = [
            {
                id: this.menuId++, label: 'menu.administration.administration',
                items: [
                    {
                        id: this.menuId++,
                        label: 'menu.administration.personnel',
                        link: 'personnel',
                        visible: this.droitService.checkPermission(this.droitService.getModulePermissions(DroitModule.USER))
                    },
                    {
                        id: this.menuId++,
                        label: 'menu.administration.materiels',
                        link: 'materiels',
                        visible: this.droitService.checkPermission(this.droitService.getModulePermissions(DroitModule.MATERIEL))
                    },
                    {
                        id: this.menuId++,
                        label: 'menu.administration.immobiliers',
                        link: 'immobiliers',
                        visible: this.droitService.checkPermission(this.droitService.getModulePermissions(DroitModule.BATIMENT))
                    },
                    {
                        id: this.menuId++,
                        label: 'menu.administration.groupe_acces',
                        link: 'permissions',
                        visible: this.droitService.checkPermission(this.droitService.getModulePermissions(DroitModule.GROUPE))
                    }
                ]
            },
            {
                id: this.menuId++,
                label: 'menu.agenda',
                link: 'agenda',
                visible: this.droitService.checkPermission(this.droitService.getModulePermissions(DroitModule.AGENDA))
            },
            {
                id: this.menuId++, label: 'menu.patient.patient',
                items: [
                    { id: this.menuId++, label: 'menu.patient.annuaire', visible: true },
                    { id: this.menuId++, label: 'menu.patient.rdv', visible: true },
                    { id: this.menuId++, label: 'menu.patient.consultations', visible: true },
                    { id: this.menuId++, label: 'menu.patient.hospitalisations', visible: true }
                ]
            },
            {
                id: this.menuId++, label: 'menu.laboratoire.laboratoire',
                items: [
                    { id: this.menuId++, label: 'menu.laboratoire.analyses', visible: true },
                    { id: this.menuId++, label: 'menu.laboratoire.resultats', visible: true }
                ]
            },
            { id: this.menuId++, label: 'menu.facturation', visible: true }
        ];

        for (const m of this.menus) {
            this.updateVisibility(m);
        }
    }

    updateVisibility(menu: MenuItem) {
        if (menu?.items?.length) {
            menu.visible = menu.items.some(c => c.visible);
        }
    }
}