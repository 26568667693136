import { Pipe, PipeTransform } from "@angular/core";
import { DroitCode } from './../models/dto/droit.dto';
import { DroitService } from './../services/droit.service';

@Pipe({ name: 'hasPermission', pure: false })
export class PermissionPipe implements PipeTransform {

    constructor(private droitSerice: DroitService) {
    }

    transform(codes: DroitCode[] | DroitCode): boolean {
        return this.droitSerice.checkPermission(codes);
    }
}