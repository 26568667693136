import { Injectable } from "@angular/core";
import { filter, take } from 'rxjs/operators';
import { Socket, io } from 'socket.io-client';
import { AppconfService } from './../../core/services/app-conf.service';
import { UserService } from './../api/user.service';
import { SocketData, SocketType } from './../models/interfaces/socket.data';
import { SynDialogService } from './dialog.service';
import { DroitService } from './droit.service';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})
export class IoService {

    socket: Socket;

    constructor(private userService: UserService,
        private dialogService: SynDialogService,
        private droitService: DroitService,
        private languageService: LanguageService,
        private appConfService: AppconfService) {
    }

    /**
     * Initialise la websocket pour intercepter les messages venant du back
     */
    initSocket() {
        this.userService.currentUser.pipe(take(1), filter(us => !!us?.id)).subscribe(user => {
            this.socket = io(`${this.appConfService.getAppConf().comUrl}`,
                {
                    autoConnect: true,
                    reconnection: true,
                    auth: { key: `${this.userService.keycloakUser.getValue()?.sid}|${user.id}` },
                    withCredentials: true,
                    transports: ['websocket']
                }
            );
            this.socket.on('connexion', (data: SocketData<string>) => console.info(data.info));
            this.socket.on('inform', (data: SocketData<unknown>) => this.processData(data));
        });
    }

    /**
     * Analyse les informations de la socket pour appliquer le traitement approprie
     * @param data 
     */
    processData(data: SocketData<any>) {
        let sms = '';
        switch (data?.type) {
            case SocketType.APP:
                break;
            case SocketType.GROUPE:
                sms = this.droitService.handleGroupeEventSms(data);
                break;
            case SocketType.USER:
                sms = this.droitService.handleUserEventSms(data);
                break;
            default:
                break;
        }
        this.dialogService.toggleMainDialog.next({
            state: true, data: {
                header: 'application.titreSmsSocket',
                content: `${this.languageService.iTranslate(sms)}<br>${this.languageService.iTranslate('application.smsRefreshApp')}`,
                noLabel: 'shared.fermer',
                noAction: () => this.dialogService.toggleMainDialog.next({ state: false }),
                yesLabel: 'shared.recharger',
                yesAction: () => window.location.reload()
            }
        });
    }
}