<div fxLayout="column" fxLayoutAlign="space-around start" class="syn-input">

    <label class="label" [class.label-required]="required" *ngIf="label?.length" [for]="idControl">{{label}}</label>

    <div fxLayout="row" class="fullW" fxLayoutAlign="start center">
        <input *ngIf="!textarea" class="syn-form-control-input icon-info" [class.has-errors]="errors && field.touched"
            [type]="inputType" [maxlength]="maxLength" [id]="idControl" [name]="idControl" (change)="onChangeValue()"
            (keyup)="onKeyup()" (blur)="onBlur()" (ngModelChange)="value = $event" [(ngModel)]="value"
            [placeholder]="placeholder" [disabled]="readonly" [required]="required" />

        <textarea *ngIf="textarea" class="syn-form-control-input" [rows]="rows" [cols]="cols"
            [class.has-errors]="!!errors" [maxlength]="maxLength" [id]="idControl" [name]="idControl"
            (change)="onChangeValue()" (keyup)="onKeyup()" (blur)="onBlur()" (ngModelChange)="value = $event"
            [(ngModel)]="value" [placeholder]="placeholder" [disabled]="readonly" [required]="required"></textarea>

        <button type="button" *ngIf="labelInfo?.length && !textarea" (click)="clickOnInfo.next(value)"
            class="input-suffix-btn pointer" [class.info-text]="!withIcon" role="button">
            <ng-container *ngIf="!withIcon">{{labelInfo}}</ng-container>
            <syn-icon [title]="labelInfo" *ngIf="withIcon" classes="tWhite" size="lg" [name]="icon"></syn-icon>
        </button>
    </div>
    <syn-form-errors [field]="field" [messageGroupKey]="messageGroupKey" [globalErrors]="globalErrors">
    </syn-form-errors>
</div>