import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { BaseDialogComponent } from 'src/app/shared/components/utils/base-dialog.component';
import { confirmValidator } from 'src/app/shared/components/utils/custom.validators';
import { SantynelleResponse } from 'src/app/shared/models/dto/response.dto';
import { CryptoService } from 'src/app/shared/services/crypto.service';
import { UserService } from './../../../shared/api/user.service';
import { ResponseCode } from './../../../shared/models/dto/response.dto';
import { User } from './../../../shared/models/dto/user.dto';
import { FormErrors } from './../../../shared/models/enum/errors.enum';
import { LanguageService } from './../../../shared/services/language.service';
import { ReactiveService } from './../../../shared/services/reactive.service';

@Component({
  selector: 'syn-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent extends BaseDialogComponent implements OnInit {

  emailForm: FormGroup;
  currentUser: User;
  FormErrors = FormErrors;

  constructor(protected override injector: Injector,
    private userService: UserService,
    private reactiveService: ReactiveService,
    private cryptoService: CryptoService,
    private languageService: LanguageService) {
    super(injector);
  }

  ngOnInit(): void {
    this.currentUser = this.userService.currentUser.getValue();
    this.emailForm = new FormGroup({
      newEmail: new FormControl(),
      cnfEmail: new FormControl(),
      password: new FormControl(),
    }, { validators: confirmValidator('newEmail', 'cnfEmail') });
  }

  apply() {
    const data = this.emailForm.getRawValue();
    this.userService.updateEmail({
      id: this.currentUser.id,
      password: CryptoService.encode(data.password, this.currentUser.id),
      email: data.cnfEmail
    }).pipe(take(1)).subscribe((res: SantynelleResponse<boolean>) => {
      this.reactiveService.snakBarService.handleResponse(res.code, res.message ? this.languageService.iTranslate(res.message) : null, true);
      if (res.code === ResponseCode.ACTION_SUCCESS && res.data) {
        this.userService.logout();
        this.close();
      }
    });
  }
}
