import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { KeycloakEventType } from 'keycloak-angular';
import { BehaviorSubject, Observable, filter, lastValueFrom } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { SantynelleResponse } from '../models/dto/response.dto';
import { PreferedLanguage } from '../models/enum/user-shared.enum';
import { SynKeycloakService } from './../../core/services/keycloak.service';
import { Droit } from './../models/dto/droit.dto';
import { User } from './../models/dto/user.dto';
import { Etat } from './../models/enum/state.enum';
import { Page, SearchOption, SortDirection } from './../models/filter.interface';
import { Column, ColumnType, SortOrder } from './../models/interfaces/datatable.interface';
import { FileResponse, ImageFileResponse } from './../models/interfaces/file.interface';
import { UserEmailRequest, UserEnvironment, UserPasswordRequest, UserRequest } from './../models/request/user.request';
import { SessionKey } from './../models/session.keys';
import { ApplicationService } from './../services/application.service';
import { DroitService } from './../services/droit.service';
import { LanguageService } from './../services/language.service';
import { TreeService } from './../services/tree.service';
import { ApiBaseService } from './api-base.service';
import { ObjectUtils } from '../components/utils/object.utils';

@Injectable({
    providedIn: 'root'
})
export class UserService extends ApiBaseService {

    currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    keycloakUser: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    public isConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readonly userOrder: SortOrder = { field: 'nom', direction: SortDirection.ASC };
    public userColumns: Column[] = [
        { field: 'matricule', sortable: true },
        { field: 'nom', sortable: true, defaultSort: true },
        { field: 'prenoms', sortable: true },
        { field: 'email', sortable: true },
        { field: 'telephone', sortable: true, displayFn: (user: User) => ObjectUtils.formatTel(user.telephone, user.portable) },
        { field: 'actif', type: ColumnType.BOOLEEN }
    ];

    constructor(private http: HttpClient,
        private keycloakService: SynKeycloakService,
        private droitService: DroitService,
        private languageService: LanguageService) {
        super('user');
        this.keycloakService.kcService.isLoggedIn().then(state => this.isConnected.next(state));
    }

    checkSession() {
        this.keycloakService.kcService.keycloakEvents$.pipe(filter(e => e.type === KeycloakEventType.OnTokenExpired)).subscribe(() => this.logout());
    }

    /**
     * Ajoute un user
     * @param user 
     * @returns 
     */
    createUser(user: UserRequest): Observable<SantynelleResponse<User>> {
        return this.http.post<SantynelleResponse<User>>(this.baseUrl, user);
    }

    /**
     * Modifie les infos d'un user
     * @param user 
     * @returns 
     */
    editUser(user: UserRequest): Observable<SantynelleResponse<boolean>> {
        return this.http.put<SantynelleResponse<boolean>>(this.baseUrl, user);
    }

    /**
     * Renvoie un user
     * @param id 
     * @param relations 
     * @returns 
     */
    getUser(id: string, relations?: string[]): Observable<SantynelleResponse<User>> {
        const query = this.getRelationsQuery('', relations);
        return this.http.get<SantynelleResponse<User>>(`${this.baseUrl}/${id}${query}`);
    }

    /**
     * Renvoie un user kycloak
     * @param id 
     * @returns 
     */
    getKcUser(id: string): Observable<SantynelleResponse<any>> {
        return this.http.get<SantynelleResponse<any>>(`${this.baseUrl}/kcUser/${id}`);
    }

    /**
     * Pagine des user
     * @param filter 
     * @param page 
     * @param relations 
     * @returns 
     */
    paginate(filter?: SearchOption, page?: Page, relations?: string[]): Observable<SantynelleResponse<User[]>> {
        let query = this.getPaginationQuery('', page);
        query = this.getRelationsQuery(query, relations);
        return this.http.post<SantynelleResponse<User[]>>(`${this.baseUrl}/list/paginate${query}`, filter);
    }

    /**
     * Recherche des user
     * @param filter 
     * @param relations 
     * @returns 
     */
    search(filter?: SearchOption, relations?: string[]): Observable<SantynelleResponse<User[]>> {
        const query = this.getRelationsQuery('', relations);
        return this.http.post<SantynelleResponse<User[]>>(`${this.baseUrl}/list/full${query}`, filter);
    }

    /**
     * Charge l'env du user connecte
     * @returns 
     */
    getEnvironnement(): Observable<SantynelleResponse<UserEnvironment>> {
        return this.http.get<SantynelleResponse<UserEnvironment>>(`${this.baseUrl}/get/environnement`)
            .pipe(take(1), tap((rep: SantynelleResponse<UserEnvironment>) => this.initUserEnv(rep.data)));
    }

    /**
     * Modifie le mot de passe d'un user
     * @param request 
     * @returns 
     */
    updatePassword(request: UserPasswordRequest): Observable<SantynelleResponse<boolean>> {
        return this.http.put<SantynelleResponse<boolean>>(`${this.baseUrl}/updatePassword`, request);
    }

    /**
     * Modifie le mot de passe d'un user (admin ou mdp oublie)
     * @param request 
     * @returns 
     */
    changePassword(request: UserPasswordRequest): Observable<SantynelleResponse<boolean>> {
        return this.http.put<SantynelleResponse<boolean>>(`${this.baseUrl}/changePassword`, request, { headers: this.publicRequestHeader });
    }

    /**
     * Modifie le mail d'un user
     * @param request 
     * @returns 
     */
    updateEmail(request: UserEmailRequest): Observable<SantynelleResponse<boolean>> {
        return this.http.put<SantynelleResponse<boolean>>(`${this.baseUrl}/updateEmail`, request);
    }

    /**
     * Modifie l'email d'un user par admin
     * @param request 
     * @returns 
     */
    changeEmail(request: UserEmailRequest): Observable<SantynelleResponse<boolean>> {
        return this.http.put<SantynelleResponse<boolean>>(`${this.baseUrl}/changeEmail`, request);
    }

    /**
     * Envoie un lien pour changer de mdp
     * @param request 
     * @returns 
     */
    forgetPassword(request: UserEmailRequest): Observable<SantynelleResponse<boolean>> {
        return this.http.put<SantynelleResponse<boolean>>(`${this.baseUrl}/forgetPassword`, request, { headers: this.publicRequestHeader });
    }

    /**
     * Active/Desactive un user
     * @param id 
     * @param state 
     * @returns 
     */
    toggleUser(id: string, state: Etat): Observable<SantynelleResponse<boolean>> {
        return this.http.put<SantynelleResponse<boolean>>(`${this.baseUrl}/toggle/${id}/${state}`, null);
    }

    /**
     * Modifie les groupes du user
     * @param idUser 
     * @param groupeIDs 
     * @returns 
     */
    editGroupes(idUser: string, groupeIDs: string[]): Observable<SantynelleResponse<boolean>> {
        return this.http.put<SantynelleResponse<boolean>>(`${this.baseUrl}/edit/groupes`, { id: idUser, groupes: groupeIDs });
    }

    /**
     * Envoie un mail de verification de compte KC
     * @param idUser 
     * @returns 
     */
    sendVerificationMail(idUser: string): Observable<SantynelleResponse<boolean>> {
        return this.http.post<SantynelleResponse<boolean>>(`${this.baseUrl}/verificationEmail`, { id: idUser });
    }

    /**
     * Change la photo de profil du user
     * @param data 
     * @returns 
     */
    updatePhoto(data: FormData): Observable<SantynelleResponse<FileResponse<ImageFileResponse>>> {
        return this.http.put<SantynelleResponse<FileResponse<ImageFileResponse>>>(`${this.baseUrl}/updatePhoto`, data);
    }

    /**
     * Supprime la photo d'un user
     * @param idUser 
     * @returns 
     */
    deletePhoto(idUser: string): Observable<SantynelleResponse<boolean>> {
        return this.http.delete<SantynelleResponse<boolean>>(`${this.baseUrl}/deletePhoto/${idUser}`);
    }

    /**
     * Deconnecte le user de l'app
     */
    async logout() {
        await lastValueFrom(this.http.post<Observable<SantynelleResponse<boolean>>>(`${this.baseUrl}/logout`, null).pipe(take(1)));
        ApplicationService.deleteSession(SessionKey.CURRENT_LANG);
        this.isConnected.next(false);
        await this.keycloakService.logout();
    }

    /**
     * Initialise les donnees de l'env user
     * @param userEnv 
     */
    initUserEnv(userEnv: UserEnvironment) {
        if (userEnv) {
            let droits: Droit[] = [];
            const groupes = userEnv.user.groupes ?? [];
            if (groupes.length) {
                droits = this.droitService.computeDroits(groupes);
            }

            if (!this.languageService.sessionLanguage?.length) {
                const lang = userEnv.user.langue_preferee === PreferedLanguage.CONNEXION_LANGUAGE ?
                    this.languageService.kcLanguage : userEnv.user.langue;
                this.languageService.changeLanguage(lang);
            }
            this.droitService.connectedUserDroits.next(droits);
            this.droitService.connectedUserGroupes.next(userEnv.user.groupes ?? []);
            this.droitService.droitsDisponibles.next(userEnv.droits);
            this.droitService.arbreDroits.next(TreeService.createDroitsTree(userEnv.droits));
            this.keycloakUser.next(this.keycloakService.getTokenParsed());
            this.currentUser.next(userEnv.user);
            this.droitService.ready.next(true);
        }
    }

    /**
     * Verifie si ID correspond a celui du user connecte
     * @param id 
     * @returns 
     */
    isConnectedUser(id: string): boolean {
        return id === this.currentUser.getValue()?.id;
    }

}