import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable, debounceTime, of, takeWhile } from 'rxjs';
import { FormErrors } from '../../models/enum/errors.enum';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'syn-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss']
})
export class FormErrorsComponent implements AfterViewInit, OnDestroy {

  @Input() field: AbstractControl;
  @Input() messageGroupKey: string = 'validation';
  @Input() globalErrors: string | string[] = null;
  globalError: string = '';
  subscription: Observable<any>;

  constructor(private languageService: LanguageService) {
  }

  get errors() {
    return this.field?.errors;
  }

  get minlength() {
    return this.errors[FormErrors.MINLENGTH]?.requiredLength;
  }

  get maxlength() {
    return this.errors[FormErrors.MAXLENGTH]?.requiredLength;
  }

  get value() {
    return this.errors[FormErrors.NOT_EQUAL]?.value;
  }

  get min() {
    return this.errors[FormErrors.MIN]?.min;
  }

  get max() {
    return this.errors[FormErrors.MAX]?.max;
  }

  get message() {
    const keys = Object.keys(this.errors);
    const fErrors = [
      { key: FormErrors.INVALID_DATE },
      { key: FormErrors.REQUIRED },
      { key: FormErrors.NUMBER },
      { key: FormErrors.MIN, params: { min: this.min } },
      { key: FormErrors.MAX, params: { max: this.max } },
      { key: FormErrors.EMAIL },
      { key: FormErrors.TEL },
      { key: FormErrors.PATTERN },
      { key: FormErrors.NOT_EQUAL, params: { value: this.value } },
      { key: FormErrors.NOT_SAME },
      { key: FormErrors.PASSWORD },
      { key: FormErrors.MINLENGTH, params: { minlength: this.minlength } },
      { key: FormErrors.MAXLENGTH, params: { maxlength: this.maxlength } }
    ];
    for (const error of fErrors) {
      if (keys.includes(error.key)) {
        return this.languageService.translate(`${this.messageGroupKey}.${error.key}`, error.params ?? null);
      }
    }
    return of(null);
  }

  ngAfterViewInit(): void {
    if (this.field?.parent) {
      this.field.parent.valueChanges.pipe(takeWhile(() => !!this.globalErrors?.length), debounceTime(100)).subscribe(() => {
        const keys = Array.isArray(this.globalErrors) ? this.globalErrors : [this.globalErrors];
        this.globalError = '';
        const errors = this.field.parent?.errors;
        if (errors) {
          const error = keys.find(key => !!errors[key]);
          if (error) {
            this.globalError = `${this.messageGroupKey}.${error}`;
          }
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.globalErrors = null;
  }

}
