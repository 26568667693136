<div *ngIf="options?.length" [fxLayout]="flexDirection" [fxLayoutAlign]="flexAlign" class="syn-button-toggle fullW"
    (mouseleave)="onBlur()" [class.disabled]="readonly">
    <input [hidden]="true" [id]="idControl" [name]="idControl" (ngModelChange)="value = $event" [(ngModel)]="value"
        [disabled]="readonly" />
    <button type="button" *ngFor="let op of options" class="btn-toggle pointer" [class.horizontal]="!isVertical"
        [class.btn-toggle-selected]="isChecked(op)" [class.disabled]="readonly" role="button" [disabled]="readonly"
        (click)="select(op)">
        {{op[labelIndex] | translate}}
    </button>
    <syn-form-errors [field]="field" [messageGroupKey]="messageGroupKey" [globalErrors]="globalErrors">
    </syn-form-errors>
</div>