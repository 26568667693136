import { Pipe, PipeTransform } from "@angular/core";
import { UserService } from './../api/user.service';

@Pipe({ name: 'isConnectedUser' })
export class ConnectedUserPipe implements PipeTransform {

    constructor(private userService: UserService) {
    }

    transform(id: string): boolean {
        return this.userService.isConnectedUser(id);
    }
}