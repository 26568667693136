<syn-modal [header]="'menu.support' | translate" (closeModal)="close()">
    <div content>
        <syn-tabs>
            <syn-tab label="{{'core.about.contact' | translate}}" [icon]="IconName.EMAIL">
                <div content>
                    {{'core.about.contact' | translate}}
                </div>
            </syn-tab>
            <syn-tab label="{{'core.about.legal' | translate}}" [icon]="IconName.BALANCE">
                <div content>
                    {{'core.about.legal' | translate}}
                </div>
            </syn-tab>
            <syn-tab label="{{'core.about.apropos' | translate}}" [icon]="IconName.INFO">
                <div content>
                    {{'core.about.apropos' | translate}}
                </div>
            </syn-tab>
        </syn-tabs>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" footer>
        <syn-button color="warn" [label]="'shared.fermer' | translate" (onclick)="close()"></syn-button>
    </div>
</syn-modal>