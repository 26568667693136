import { Injectable } from "@angular/core";
import * as _ from 'lodash';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Droit, DroitCode, DroitModule } from './../models/dto/droit.dto';
import { Groupe } from './../models/dto/groupe.dto';
import { User } from './../models/dto/user.dto';
import { SocketAction, SocketData } from './../models/interfaces/socket.data';
import { TreeData } from './../models/interfaces/tree.interface';

@Injectable({
    providedIn: 'root'
})
export class DroitService {

    arbreDroits: ReplaySubject<TreeData[]> = new ReplaySubject<TreeData[]>(1);
    ready: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
    droitsDisponibles: BehaviorSubject<Droit[]> = new BehaviorSubject<Droit[]>([]);
    // Attention: contient tous les groupes du user connecte meme les groupes inactifs
    connectedUserGroupes: BehaviorSubject<Groupe[]> = new BehaviorSubject<Groupe[]>([]);
    connectedUserDroits: BehaviorSubject<Droit[]> = new BehaviorSubject<Droit[]>([]);

    /**
     * Renvoie les droits lies a un module
     * @param module 
     * @returns 
     */
    getModulePermissions(module: DroitModule): DroitCode[] {
        return Object.values(DroitCode).filter(c => c.includes(module.toUpperCase()));
    }

    /**
     * Verifie que le user a acces a au moins un droit du ou des modules
     * @param modules 
     * @returns 
     */
    hasAnyPermission(modules: DroitModule[] | DroitModule): boolean {
        modules = Array.isArray(modules) ? modules : [modules];
        for (const m of modules) {
            if (this.checkPermission(this.getModulePermissions(m))) {
                return true;
            }
        }
        return false;
    }

    /**
     * Verifie que le user a acces a au moins un des droits
     * @param codes 
     * @returns 
     */
    checkPermission(codes: DroitCode[] | DroitCode): boolean {
        codes = Array.isArray(codes) ? codes : [codes];
        const modules = codes.map(c => `${c.split('_')[0]}_ADMIN`) as DroitCode[];
        modules.push(DroitCode.APPLICATION_ADMIN);
        codes = _.uniq([...modules, ...codes]);
        return this.connectedUserDroits.getValue().some(d => codes.includes(d.code as DroitCode));
    }

    /**
     * Consolide les droits des groupes en une liste unique de droits
     * @param groupes 
     * @returns 
     */
    computeDroits(groupes: Groupe[] = []): Droit[] {
        let droits: Droit[] = [];
        groupes.filter(g => g.actif).forEach(ga => droits = [...droits, ...(_.cloneDeep(ga.droits) ?? [])]);
        return _.uniqBy(droits, 'code');
    }

    /**
     * Traite les notifications concernant les groupes
     * @param data 
     * @returns 
     */
    handleGroupeEventSms(data: SocketData<Groupe>): string {
        let sms = '';
        switch (data.action) {
            case SocketAction.UPDATE:
                sms = 'application.smsModificationGroupe';
                break;
            case SocketAction.DESACTIVATE:
                sms = 'application.smsDesactivationGroupe';
                break;
            case SocketAction.DELETE:
                sms = 'application.smsSuppressionGroupe';
                break;
            default:
                break;
        }
        return sms;
    }

    /**
     * Traite les notifications concernant les users
     * @param data 
     * @returns 
     */
    handleUserEventSms(data: SocketData<User>): string {
        let sms = '';
        switch (data.action) {
            case SocketAction.UPDATE:
                sms = 'application.smsModificationCompte';
                break;
            case SocketAction.DESACTIVATE:
                sms = 'application.smsDesactivationCompte';
                break;
            default:
                break;
        }
        return sms;
    }
}