import { Component, Input } from '@angular/core';
import { MenuItem } from './../../../shared/models/interfaces/menu.interface';
import { NavigationService } from './../../../shared/services/navigation.service';

@Component({
  selector: 'syn-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent {

  @Input() menu: MenuItem[] = [];

  constructor(private navigationService: NavigationService) { }

  clickOnMenu(m: MenuItem) {
    m.expanded = m.items && !m.expanded;
    if (m?.click) {
      m.click();
    }
    if (m?.link) {
      this.navigationService.navigate(`/${m.link}`);
    }
  }
}
