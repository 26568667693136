<mat-toolbar class="header">
    <mat-toolbar-row class="header-bar">
        <div fxShow="true" fxHide.gt-sm="true">
            <syn-icon [name]="IconName.MENU_BAR" (onclick)="sidenav?.toggle()"></syn-icon>
        </div>

        <div>
            <img alt="Logo" src="./assets/images/logo.png" style="display: flex; height: 45px;" />
        </div>

        <div class="top-menu" fxShow="true" fxHide.lt-md="true">
            <syn-top-menu *ngIf="!hideMenu && (userService.isConnected | async)" [menu]="menu"></syn-top-menu>
        </div>

        <span class="app-spacer"></span>

        <div fxLayout="row" fxLayoutAlign="start center">

            <ng-container *ngIf="userService.isConnected | async">
                <syn-icon [title]="'menu.profil.desc' | translate" [name]="IconName.USER" classes="tWhite"
                    [cdkMenuTriggerFor]="profMenu.mainMenu">
                </syn-icon>
                <syn-menu #profMenu [menuItems]="profileMenu"></syn-menu>

                <div fxShow="false" fxHide.gt-sm="false">
                    <syn-icon [title]="'menu.notifications' | translate" [name]="IconName.NOTIFICATION"
                        classes="tWhite">
                    </syn-icon>
                </div>
            </ng-container>

            <img class="btnFlag" [alt]="'menu.langue' | translate" [title]="'menu.langue' | translate"
                [cdkMenuTriggerFor]="menuLangue.mainMenu" src="./assets/images/{{languageService.currentLang}}.png" />
            <syn-menu #menuLangue [menuItems]="langueMenu"></syn-menu>
        </div>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="userService.isConnected | async" class="title-bar">
        <div class="page-title">{{title | translate}}</div>
        <span class="app-spacer"></span>
        <div fxLayout="row" fxLayoutAlign="start center">
            <syn-icon *ngIf="url !== dashboardMenu" [title]="'menu.tdb' | translate" [name]="IconName.HOME"
                [routerLink]="dashboardMenu">
            </syn-icon>
        </div>
    </mat-toolbar-row>
</mat-toolbar>