import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ResponseCode, SantynelleResponse } from './../models/dto/response.dto';
import { SynSnackBarService } from './snackbar.service';


@Injectable({
    providedIn: 'root'
})
export class ReactiveService {

    loading: boolean = false;

    constructor(public snakBarService: SynSnackBarService) {
    }

    call<T>(ws: Observable<SantynelleResponse<T>>, nextFunction: (value: SantynelleResponse<T>) => void, entity?: string, showSuccess?: boolean) {
        this.loading = true;
        ws.pipe(take(1)).subscribe({
            next: (res: SantynelleResponse<T>) => {
                if (res.code === ResponseCode.ACTION_SUCCESS) {
                    nextFunction(res);
                }
                this.loading = false;
                this.snakBarService.handleResponse(res.code, res.message, showSuccess, entity);
            },
            error: () => {
                this.snakBarService.handleResponse(ResponseCode.UNKWONED_ERROR);
                this.loading = false;
            }
        });
    }


}