import { AfterViewInit, Component, ContentChildren, ElementRef, EventEmitter, Input, OnDestroy, Output, QueryList, ViewChild } from '@angular/core';
import { debounceTime, takeWhile } from 'rxjs';
import { ApplicationService } from '../../services/application.service';
import { IconName } from './../icon/icon.model';
import { TabComponent } from './tab/tab.component';

@Component({
  selector: 'syn-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterViewInit, OnDestroy {

  @Input() selectedIndex: number = 0;
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  @ViewChild('tabGroup', { static: true }) tabGroup: ElementRef;
  visibleTabs: number[] = [];
  IconName = IconName;
  displayLeft: boolean = false;
  displayRight: boolean = false;
  nbVisible: number = 0;
  width: number = 500;

  constructor(private appService: ApplicationService) {
    this.appService.resizingWindow.pipe(debounceTime(100), takeWhile(() => !!this.tabs)).subscribe(() => this.computeTabs());
  }

  ngAfterViewInit(): void {
    const tm = setTimeout(() => {
      this.computeTabs();
      clearTimeout(tm);
    });
  }

  isSelected(index: number) {
    return this.selectedIndex === index;
  }

  isVisible(index: number) {
    return this.visibleTabs.includes(index);
  }

  select(index: number) {
    this.selectedIndex = index;
    this.selectedIndexChange.next(index);
  }

  nextTab() {
    const lastIndex = this.visibleTabs[this.visibleTabs.length - 1];
    const tabLength = this.tabs.length - 1;
    if (lastIndex < tabLength) {
      this.visibleTabs.push(lastIndex + 1);
      this.selectedIndex++;
      if (this.visibleTabs.length > this.nbVisible) {
        this.visibleTabs.shift();
      }
    } else if (this.selectedIndex < tabLength) {
      this.selectedIndex++;
    }
  }

  prevTab() {
    let firstIndex = this.visibleTabs[0];
    if (firstIndex > 0) {
      firstIndex--;
      this.visibleTabs.unshift(firstIndex);
      if (this.visibleTabs.length > this.nbVisible) {
        this.visibleTabs.pop();
      }
    }
    if (this.selectedIndex > 0) {
      this.selectedIndex--;
    }
  }

  private computeTabs() {
    this.width = this.tabGroup.nativeElement.offsetWidth;
    const nb = this.tabs.length;
    this.nbVisible = Math.round(this.width / 50) - 2;
    this.displayLeft = nb > this.nbVisible;
    this.displayRight = this.displayLeft;
    if (!this.displayLeft) {
      this.nbVisible = nb;
    }
    this.visibleTabs = [];
    this.selectedIndex = 0;
    for (let i = this.selectedIndex; i < this.nbVisible; i++) {
      this.visibleTabs.push(i);
    }
  }

  ngOnDestroy(): void {
    this.tabs = null;
  }

}
