import { SortOrder } from "./interfaces/datatable.interface";

export enum PageSize {
    SIZE_20 = 20,
    SIZE_50 = 50,
    SIZE_100 = 100
}

export enum SearchOperator {
    EGAL = 'EGAL',
    SUPERIEUR = 'SUPERIEUR',
    INFERIEUR = 'INFERIEUR',
    SUPERIEUR_EGAL = 'SUPERIEUR_EGAL',
    INFERIEUR_EGAL = 'INFERIEUR_EGAL',
    CONTIENT = 'CONTIENT',
    COMMENCE = 'COMMENCE',
    FINIT = 'FINIT',
    DIFFERENT = 'DIFFERENT',
    NON_NULL = 'NON_NULL',
    NULL = 'NULL',
    INCLUS_DANS = 'INCLUS_DANS',
    HORS_DE = 'HORS_DE',
    ENTRE = 'ENTRE'
}

export enum SearchType {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    BOOLEEN = 'BOOLEEN',
    DATE = 'DATE'
}

export enum SearchCondition {
    ET = 'AND',
    OU = 'OU'
}

export enum SortDirection {
    ASC = 'ASC',
    DESC = 'DESC'
}

export class Page {
    currentPage: number = 1;
    pageSize: number = PageSize.SIZE_20;
    nbPage?: number;
    nbRows?: number;
}

export interface FilterOption {
    field: string;
    operator: SearchOperator;
    type: SearchType;
    value: any;
}

export interface Filter {
    criteria?: FilterOption;
    condition?: SearchCondition;
    filters?: Filter[];
}

export interface SearchOption {
    filters: Filter[];
    condition?: SearchCondition;
    orders: SortOrder[];
}