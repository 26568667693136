import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, debounceTime } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    public toggleMainLoader: Subject<{ state: boolean, sms?: string }> = new Subject<{ state: boolean, sms?: string }>();
    public mainLoaderConfig: { name: string, state: boolean, sms: string } = { name: this.spinnerName, state: false, sms: '' };

    constructor(private spinner: NgxSpinnerService) {
        this.initMainLoader();
    }

    get spinnerName() {
        return _.uniqueId('spinner');
    }

    toggleSpinner(state: boolean, name?: string) {
        if (state) {
            this.spinner.show(name);
        } else {
            this.spinner.hide(name);
        }
    }


    /**
     * Initialise le loader principal de l'application
     */
    initMainLoader() {
        this.toggleMainLoader.pipe(debounceTime(100)).subscribe(({ state, sms }) => {
            sms = sms?.length ? sms : '';
            this.mainLoaderConfig.state = state;
            this.mainLoaderConfig.sms = sms;
            this.toggleSpinner(state, this.mainLoaderConfig.name);
        });
    }
}