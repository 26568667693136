<div fxLayout="row" class="syn-slide-toggle" fxLayoutAlign="start center">
    <label *ngIf="labelBefore">{{label}}&nbsp;</label>
    <label class="toggler-wrapper pointer" [class.disabled]="readonly">
        <input class="native-checkbox" [hidden]="true" type="checkbox" [id]="idControl" [name]="idControl"
            (ngModelChange)="value = $event" [(ngModel)]="value" [disabled]="readonly" />
        <div class="toggler-slider">
            <div class="toggler-knob"></div>
        </div>
    </label>
    <label *ngIf="labelAfter">&nbsp;{{label}}</label>
</div>