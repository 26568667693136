import { Component, OnInit } from '@angular/core';
import { IconName } from './../icon/icon.model';

@Component({
  selector: 'syn-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  sSizes: number[] = [0.1, 0.1, 0.1];
  leftSizes: number[] = [];
  rightSizes: number[] = [];
  IconName: typeof IconName = IconName;
  leftState: number = 0;

  ngOnInit(): void {
    this.collapseRight();
  }

  private updateSizes() {
    [20, 25, 30].forEach((s, i) => {
      switch (this.leftState) {
        case -1:
          this.leftSizes[i] = this.sSizes[i];
          this.rightSizes[i] = 100 - this.sSizes[i];
          break;
        case 0:
          this.leftSizes[i] = s;
          this.rightSizes[i] = 100 - s;
          break;
        case 1:
          this.rightSizes[i] = this.sSizes[i];
          this.leftSizes[i] = 100 - this.rightSizes[i];
          break;
        default:
          break;
      }
    });
  }

  toggleLeft(big: boolean = false) {
    if (big) {
      this.leftState = this.leftState === 1 ? 0 : 1;
    } else {
      const state = this.leftState === 0 ? -1 : 0;
      this.leftState = this.leftState === 1 ? 0 : state;
    }
    this.updateSizes();
  }

  leftFx(i: number) {
    return this.leftSizes[i] + '%';
  }

  rightFx(i: number) {
    return this.rightSizes[i] + '%';
  }

  collapseLeft() {
    this.leftState = -1;
    this.updateSizes();
  }

  restore() {
    this.leftState = 0;
    this.updateSizes();
  }

  collapseRight() {
    this.leftState = 1;
    this.updateSizes();
  }
}
