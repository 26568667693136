export enum ResponseCode {
    ACTION_SUCCESS = 600,
    ENTITY_NOT_FOUND = 601,
    ENTITY_EXISTS = 602,
    ENTITY_IS_DISABLED = 603,
    ENTITY_IS_NOT_VERIFIED = 604,
    ENTITY_IS_NOT_VALID = 605,
    ENTITY_IS_NOT_AUTHORISED = 606,
    ENTITY_IS_LOCKED = 607,
    ACTION_IS_NOT_AUTHORISED = 608,
    ACTION_FAILED = 609,
    UNKWONED_ERROR = 610,
    ACTION_NEED_CHANGES = 611,
    ACTION_BAD_REQUEST = 612,
    CUSTOM_ERROR = 613
}

export interface SantynelleResponse<T> {
    code: ResponseCode;
    date: Date;
    message: string;
    size: number;
    data: T;
}