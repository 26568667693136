import { Dialog, DialogConfig, DialogRef } from "@angular/cdk/dialog";
import { ComponentType } from "@angular/cdk/portal";
import { Injectable } from "@angular/core";
import { Subject, debounceTime } from 'rxjs';
import { DialogData } from "../models/dialog.model";
import { DialogComponent } from './../components/dialog/dialog.component';

@Injectable({
    providedIn: 'root'
})
export class SynDialogService {

    private dialogRef: DialogRef<DialogData>;

    public toggleMainDialog: Subject<{ state: boolean, data?: DialogData }> = new Subject<{ state: boolean, data?: DialogData }>();

    get width() {
        return '500px';
    }

    private defaultConf: DialogConfig<DialogData> = {
        closeOnNavigation: true,
        width: this.width,
        hasBackdrop: true,
        panelClass: 'syn-dialog-container',
        disableClose: false
    };

    constructor(public dialog: Dialog) {
        this.initMainDialog();
    }

    /**
     * Parametre pour une confirm dialog
     * @param header 
     * @param content 
     * @param yesAction 
     * @param noAction 
     * @returns 
     */
    setConfirmData(header: string, content: string | any, yesAction: () => void, noAction?: () => void): DialogConfig<DialogData> {
        return {
            data: {
                header: header,
                content: content,
                yesLabel: 'shared.oui',
                noLabel: 'shared.non',
                yesAction: () => yesAction(),
                noAction: () => noAction ? noAction() : {}
            },
            hasBackdrop: true
        };
    }

    /**
     * Ouvre une dialog
     * @param comp 
     * @param param 
     * @returns 
     */
    open(comp: ComponentType<any>, param?: any) {
        param = param ? { ...this.defaultConf, ...param } : this.defaultConf;
        return this.dialog.open<DialogData>(comp, param);
    }

    /**
     * Initialise la dialog principal de l'application
     */
    initMainDialog() {
        this.toggleMainDialog.pipe(debounceTime(500)).subscribe(({ state, data }) => {
            if (state) {
                this.dialogRef = this.open(DialogComponent, {
                    data: data,
                    hasBackdrop: true,
                    disableClose: true
                });
            } else if (this.dialogRef) {
                this.dialogRef.close();
            }
        });
    }
}