import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from './../../shared/services/language.service';
import { InterceptorException } from './../enum/core.enum';
import { AppconfService } from './../services/app-conf.service';

@Injectable({
    providedIn: 'root'
})
export class UrlInterceptor implements HttpInterceptor {

    constructor(private readonly configService: AppconfService, private languageService: LanguageService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const tab: string[] = req.url.split('?');
        if (tab.length > 0) {
            const urlWithoutParameters = tab[0];
            if (!InterceptorException.some(it => urlWithoutParameters.endsWith(it)) && !urlWithoutParameters.startsWith('http')) {
                req = req.clone({
                    url: `${this.configService.getAppConf()?.apiUrl}${req.url}`,
                    withCredentials: true,
                    headers: req.headers.append('userLanguage', this.languageService.currentLang)
                });
            }
        }
        return next.handle(req);
    }
}
