import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'syn-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent {

  static nextID = 0;
  @Input() label: string;
  @Input() value: boolean = false;
  @Input() indeterminate: boolean = false;
  @Input() readonly: boolean = false;
  @Input() labelPosition: 'after' | 'before' = 'before';
  @Input() idControl: string = `syn-selection-${SelectionComponent.nextID++}`;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  get labelBefore() {
    return this.label?.length && this.labelPosition === 'before';
  }

  get labelAfter() {
    return this.label?.length && this.labelPosition === 'after';
  }

  changeValue() {
    this.value = !this.value;
    this.valueChange.next(this.value);
  }
}
