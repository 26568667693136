export enum SnackBarType {
    SUCCESS = 'SUCCESS',
    INFO = 'INFO',
    WARNING = 'WARNING',
    ERROR = 'ERROR'
}

export interface SnackBarParam {
    content: string;
    type?: SnackBarType;
    actionLabel?: string;
    actionFn?: () => void;
}