export enum SocketType {
    APP = 'APP',
    GROUPE = 'GROUPE',
    USER = 'USER'
}

export enum SocketAction {
    NONE = 'NONE',
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DESACTIVATE = 'DESACTIVATE',
    DELETE = 'DELETE'
}

export interface SocketData<T> {
    type: SocketType;
    action: SocketAction;
    info: T;
    date: Date;
    key?: string;
    users?: string[];
}