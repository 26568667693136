import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';
import { Page, PageSize } from './../../models/filter.interface';
import { OptionModel } from './../../models/options.model';
import { IconName } from './../icon/icon.model';

@Component({
  selector: 'syn-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges, OnDestroy {

  @Input() nbItems: number;
  @Output() pageChange: EventEmitter<Page> = new EventEmitter<Page>();
  changePipeline: Subject<Page> = new Subject<Page>();

  IconName: typeof IconName = IconName;
  page: Page = { pageSize: PageSize.SIZE_20, currentPage: 1, nbPage: 1, nbRows: 0 };

  sizeOptions: OptionModel<number>[] = [PageSize.SIZE_20, PageSize.SIZE_50, PageSize.SIZE_100]
    .map(n => ({ value: n, label: n.toString() }));

  firstChange: boolean = true;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes['nbItems']) {
        this.page.currentPage = 1;
        this.page.nbRows = this.nbItems ?? 0;
        this.page.nbPage = Math.ceil(this.page.nbRows / this.page.pageSize);
        if (this.nbItems === 0) {
          this.firstChange = true;
        }
      }
    }
  }

  ngOnInit(): void {
    this.changePipeline.pipe(debounceTime(500)).subscribe(page => this.pageChange.next(page));
  }

  get minSize() {
    return Math.min(...(this.sizeOptions ?? []).map(s => s.value));
  }

  sizeChange(size: number) {
    if (!this.firstChange && size) {
      this.page.pageSize = size;
      this.page.currentPage = 1;
      this.page.nbPage = Math.ceil((this.page.nbRows ?? 0) / this.page.pageSize);
      this.changePipeline.next(this.page);
    }
    this.firstChange = false;
  }

  firstPage() {
    this.page.currentPage = 1;
    this.changePipeline.next(this.page);
  }

  previousPage() {
    this.page.currentPage--;
    this.changePipeline.next(this.page);
  }

  nextPage() {
    this.page.currentPage++;
    this.changePipeline.next(this.page);
  }

  lastPage() {
    this.page.currentPage = this.page.nbPage ?? 1;
    this.changePipeline.next(this.page);
  }

  ngOnDestroy(): void {
    this.changePipeline.complete();
    this.pageChange.complete();
  }

}
