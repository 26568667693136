import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'syn-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() header: string = '';
  @Input() hasFooter: boolean = true;
  @Input() closable: boolean = true;
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
}
