import { IconName } from './../../../shared/components/icon/icon.model';
import { Component, Injector } from '@angular/core';
import { BaseDialogComponent } from 'src/app/shared/components/utils/base-dialog.component';

@Component({
  selector: 'syn-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends BaseDialogComponent {

  IconName: typeof IconName = IconName;

  constructor(protected override injector: Injector) {
    super(injector);
  }

}
