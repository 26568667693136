<div class="syn-date-picker">
    <div fxLayout="column" fxLayoutAlign="start start">
        <div fxLayout="row" class="fullW" fxLayoutAlign="space-between center">
            <syn-icon [title]="'shared.calendar.previousYear' | translate" [name]="IconName.DOUBLE_BACK" size="lg"
                classes="tPrimary" (onclick)="changeYear(false)"></syn-icon>
            <syn-icon [title]="'shared.calendar.previousMonth' | translate" [name]="IconName.PREVIOUS"
                (onclick)="changeMonth(false)" classes="tPrimary"></syn-icon>
            <syn-select class="months" [options]="months" [(ngModel)]="currentMonth" [showPlaceholder]="false"
                [required]="true" (ngModelChange)="generateDate()"></syn-select>
            <syn-select class="years" [options]="years" [showPlaceholder]="false" [required]="true"
                (ngModelChange)="selectYear($event)" [(ngModel)]="currentYear"></syn-select>
            <syn-icon [title]="'shared.calendar.nextMonth' | translate" [name]="IconName.NEXT" (onclick)="changeMonth()"
                classes="tPrimary"></syn-icon>
            <syn-icon [title]="'shared.calendar.nextYear' | translate" [name]="IconName.DOUBLE_NEXT" size="lg"
                classes="tPrimary" (onclick)="changeYear()"></syn-icon>
        </div>
        <div fxLayout="row" class="nameRows" fxLayoutAlign="start center">
            <div *ngFor="let day of days;" class="dayName">
                {{'shared.calendar.days.' + day + '.short' | translate}}
            </div>
        </div>
        <div *ngFor="let week of dates;">
            <div fxLayout="row" fxLayoutAlign="start center">
                <div class="day pointer" *ngFor="let day of week;" [class.today]="isToday(day)"
                    [class.exclude]="isOutOfMonth(day)" [class.selected]="isSelected(day)" (click)="select(day)">
                    {{day|date:'d'}}
                </div>
            </div>
        </div>
    </div>
</div>