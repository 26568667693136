<div class="stateBar">
    <syn-icon classes="iconState" [name]="leftState > -1 ? IconName.ARROW_LEFT : IconName.ARROW_RIGHT"
        (onclick)="toggleLeft();"></syn-icon>
    <syn-icon *ngIf="leftState !== 1" [name]="IconName.FULLSCREEN" classes="iconState"
        (onclick)="toggleLeft(true);"></syn-icon>
</div>
<div class="fullH syn-layout module-content" fxLayout="row" fxLayoutAlign="space-around">
    <div [class.hidden]="leftState === -1" [class.border]="leftState === 0" class="sideLayout" fxFlex="{{leftFx(0)}}"
        fxFlex.sm="{{leftFx(1)}}" fxFlex.xs="{{leftFx(2)}}">
        <div class="sl-content">
            <ng-content select="[sideLayout]"></ng-content>
        </div>
    </div>
    <div [class.hidden]="leftState === 1" class="contentLayout" fxFlex="{{rightFx(0)}}" fxFlex.sm="{{rightFx(1)}}"
        fxFlex.xs="{{rightFx(2)}}">
        <ng-content select="[contentLayout]"></ng-content>
    </div>
</div>