import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { BaseDialogComponent } from 'src/app/shared/components/utils/base-dialog.component';
import { DialogData } from 'src/app/shared/models/dialog.model';
import { FileDestination } from 'src/app/shared/models/interfaces/file.request';
import { FileService } from 'src/app/shared/services/file.service';
import { ReactiveService } from 'src/app/shared/services/reactive.service';
import { environment } from './../../../../environments/environment';
import { UserService } from './../../../shared/api/user.service';
import { IconName } from './../../../shared/components/icon/icon.model';
import { SantynelleResponse } from './../../../shared/models/dto/response.dto';
import { User } from './../../../shared/models/dto/user.dto';
import { FormatDate } from './../../../shared/models/enum/date-format.enum';
import { PreferedLanguage, Sexe } from './../../../shared/models/enum/user-shared.enum';
import { ImageFileResponse } from './../../../shared/models/interfaces/file.interface';
import { TreeData } from './../../../shared/models/interfaces/tree.interface';
import { OptionModel } from './../../../shared/models/options.model';
import { UserRequest } from './../../../shared/models/request/user.request';
import { SynDialogService } from './../../../shared/services/dialog.service';
import { DroitService } from './../../../shared/services/droit.service';
import { LanguageService } from './../../../shared/services/language.service';
import { ValidatorService } from './../../../shared/services/validators.service';
import { AppConf } from './../../interfaces/app-conf.interface';
import { AppconfService } from './../../services/app-conf.service';

@Component({
  selector: 'syn-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent extends BaseDialogComponent implements OnInit {

  user: User;
  userForm: FormGroup;
  langueOptions: OptionModel<string>[] = environment.languages
    .map((l: string) => ({ label: `application.${l}`, value: l }));

  prefereLangueOptions: OptionModel<number>[] = [
    { label: `application.USER_LANGUAGE`, value: PreferedLanguage.USER_LANGUAGE },
    { label: `application.CONNEXION_LANGUAGE`, value: PreferedLanguage.CONNEXION_LANGUAGE }
  ];

  sexeOptions: OptionModel<string>[] = Object.values(Sexe)
    .map((g: string) => ({ label: `core.userProfile.${g}`, value: g }));

  accessOptions: OptionModel<number>[] = [
    { label: 'core.userProfile.groupes', value: 1 },
    { label: 'core.userProfile.droits', value: 2 }
  ];
  accessOption: number = this.accessOptions[0].value;

  groupes: TreeData[] = [];
  droits: TreeData[] = [];
  accessTree: TreeData[] = [];
  conf: AppConf = this.appConfService.getAppConf();
  phonePattern: string = ValidatorService.numberPattern(this.conf.phoneDigit);
  maxDateNaissance: Date = new Date();
  FormatDate = FormatDate;
  IconName: typeof IconName = IconName;

  constructor(protected override injector: Injector,
    private appConfService: AppconfService,
    private userService: UserService,
    public reactiveService: ReactiveService,
    private dialogService: SynDialogService,
    private langueService: LanguageService,
    private fileService: FileService,
    public droitService: DroitService) {
    super(injector);
  }

  ngOnInit(): void {
    this.user = this.userService.currentUser.getValue();
    this.initForm();
    const groupes = this.droitService.connectedUserGroupes.getValue().filter(ug => ug.actif)
    this.groupes = groupes.map(g => ({ id: g.id, label: g.nom }));
    this.droits = groupes.map(g => ({ id: g.id, label: g.nom, children: g.droits?.map(a => ({ id: a.code, label: `core.droits.${a.code}` })) }));
    this.initAccess(this.accessOption);
  }

  initForm() {
    this.userForm = new FormGroup({
      nom: new FormControl(this.user?.nom, Validators.required),
      prenoms: new FormControl(this.user?.prenoms),
      telephone: new FormControl(this.user?.telephone, Validators.required),
      portable: new FormControl(this.user?.portable),
      sexe: new FormControl(this.user?.sexe, Validators.required),
      date_naissance: new FormControl(this.user?.date_naissance, Validators.required),
      lieu_naissance: new FormControl(this.user?.lieu_naissance),
      langue: new FormControl(this.user?.langue, Validators.required),
      langue_preferee: new FormControl(this.user?.langue_preferee, Validators.required),
      notif_email: new FormControl({ value: this.user?.notif_email, disabled: true }, Validators.required),
      notif_app: new FormControl(this.user?.notif_app),
      notif_sms: new FormControl(this.user?.notif_sms),
      adresse: new FormControl(this.user?.adresse),
      accessOption: new FormControl(this.accessOption)
    });
  }

  initAccess(option: number) {
    switch (option) {
      case 1:
        this.accessTree = _.cloneDeep(this.groupes);
        break;
      case 2:
        this.accessTree = _.cloneDeep(this.droits);
        break;
      default:
        this.accessTree = [];
        break;
    }
  }

  updateAccess(value: number) {
    this.initAccess(value);
  }

  openFileLoader() {
    this.fileService.openLoader({
      destination: FileDestination.USER,
      ressourceId: this.user.id,
      maxFiles: 1,
      closeAfterUpload: true
    }).closed.pipe(take(1), filter(result => result?.content?.length > 0)).subscribe((res: DialogData) => {
      const resp = res.content as ImageFileResponse[];
      this.user.photo = resp[0]?.data ?? null;
    });
  }

  deletePhoto() {
    this.dialogService.open(DialogComponent,
      this.dialogService.setConfirmData(
        'user.photo', this.langueService.iTranslate(`user.smsSupprimePhoto`),
        () => this.reactiveService.call(this.userService.deletePhoto(this.user.id), (res: SantynelleResponse<boolean>) => {
          if (res.data) {
            this.user.photo = null;
          }
        }, 'user.photo')
      ));
  }

  apply() {
    const data = this.userForm.getRawValue() as UserRequest;
    delete (data as any).accessOption;
    data.id = this.user.id;
    data.date_naissance = new Date(data.date_naissance);
    this.reactiveService.call(this.userService.editUser(data), (res: SantynelleResponse<boolean>) => {
      if (res.data) {
        this.user = Object.assign(this.user, data);
        this.userService.currentUser.next(this.user);
      }
    }, 'user.compte', true);
  }

}
