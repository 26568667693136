import { Component, Input } from '@angular/core';
import { MenuItem } from './../../../shared/models/interfaces/menu.interface';
import { NavigationService } from './../../../shared/services/navigation.service';

@Component({
  selector: 'syn-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent {

  @Input() menu: MenuItem[] = [];
  @Input() padding: number = 5;

  constructor(private navigationService: NavigationService) { }

  clickOnMenu(m: MenuItem) {
    if (m.items) {
      m.expanded = !m.expanded;
    }
    if (m?.click) {
      m.click();
    }
    if (m?.link) {
      this.navigationService.navigate(`/${m.link}`);
    }
  }

}
