import { IconName } from './../../../shared/components/icon/icon.model';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationStart, Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs';
import { SynDialogService } from './../../../shared/services/dialog.service';
import { LoaderService } from './../../../shared/services/loader.service';
import { AppConf } from './../../interfaces/app-conf.interface';
import { AppconfService } from './../../services/app-conf.service';
import { MenuService } from './../../services/menu.service';
import { AboutComponent } from './../about/about.component';

@Component({
  selector: 'syn-desktop',
  templateUrl: './desktop.component.html',
  styleUrls: ['./desktop.component.scss']
})
export class DesktopComponent implements OnInit, AfterViewInit {

  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;

  conf: AppConf = this.confService.getAppConf();
  loadingEnv: boolean;
  IconName: typeof IconName = IconName;

  constructor(public menuService: MenuService,
    private router: Router,
    private loaderService: LoaderService,
    private confService: AppconfService,
    private dialogService: SynDialogService) { }

  ngOnInit(): void {
    this.router.events.pipe(filter(ev => ev instanceof NavigationStart)).subscribe(() => this.sidenav?.close());
  }

  ngAfterViewInit(): void {
    this.loaderService.toggleMainLoader.pipe(debounceTime(100)).subscribe(({ state }) => this.loadingEnv = state);
  }

  openAboutDialog() {
    this.dialogService.open(AboutComponent, { width: '700px' });
  }

}
