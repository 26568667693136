<div class="wrapper">
    <div class="header">
        <syn-header [sidenav]="sidenav" [hideMenu]="loadingEnv" [menu]="menuService.menus">
        </syn-header>
    </div>
    <div class="content">
        <mat-sidenav-container fxFlexFill class="side-container" hasBackdrop="true">
            <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over" opened="false" fxHide.gt-sm="true">
                <div fxLayout="column" fxLayoutAlign="space-around start">
                    <syn-side-menu *ngIf="!loadingEnv" [menu]="menuService.menus"></syn-side-menu>
                </div>
            </mat-sidenav>
            <mat-sidenav-content>
                <router-outlet *ngIf="!loadingEnv"></router-outlet>
            </mat-sidenav-content>
        </mat-sidenav-container>
    </div>
    <div class="footer">
        <div class="app-footer" fxLayout="row" fxLayoutAlign="space-between center">
            &copy; {{conf.appName}}
            <div fxLayout="row" fxLayoutAlign="end center">
                <syn-icon (onclick)="openAboutDialog()" classes="tWhite" [title]="'menu.support' | translate"
                    [name]="IconName.QUESTION">
                </syn-icon>
            </div>
        </div>
    </div>
</div>