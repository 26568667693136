import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { take } from 'rxjs/operators';
import { BaseDialogComponent } from 'src/app/shared/components/utils/base-dialog.component';
import { confirmValidator } from 'src/app/shared/components/utils/custom.validators';
import { ResponseCode, SantynelleResponse } from 'src/app/shared/models/dto/response.dto';
import { CryptoService } from 'src/app/shared/services/crypto.service';
import { UserService } from './../../../shared/api/user.service';
import { User } from './../../../shared/models/dto/user.dto';
import { FormErrors } from './../../../shared/models/enum/errors.enum';
import { LanguageService } from './../../../shared/services/language.service';
import { ReactiveService } from './../../../shared/services/reactive.service';

@Component({
  selector: 'syn-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent extends BaseDialogComponent implements OnInit {

  passwordForm: FormGroup;
  currentUser: User;
  FormErrors = FormErrors;

  constructor(protected override injector: Injector,
    private userService: UserService,
    private reactiveService: ReactiveService,
    private languageService: LanguageService) {
    super(injector);
  }

  ngOnInit() {
    this.currentUser = this.userService.currentUser.getValue();
    this.passwordForm = new FormGroup({
      oldPassword: new FormControl(),
      newPassword: new FormControl(),
      cnfPassword: new FormControl()
    }, { validators: confirmValidator('newPassword', 'cnfPassword') });
  }

  apply() {
    const data = this.passwordForm.getRawValue();
    const oldPassword = CryptoService.encode(data.oldPassword, this.currentUser.id);
    const newPassword = CryptoService.encode(data.newPassword, this.currentUser.id);
    this.userService.updatePassword({ id: this.currentUser.id, oldPassword: oldPassword, password: newPassword })
      .pipe(take(1)).subscribe((res: SantynelleResponse<boolean>) => {
        this.reactiveService.snakBarService.handleResponse(res.code, res.message ? this.languageService.iTranslate(res.message) : null, true);
        if (res.code === ResponseCode.ACTION_SUCCESS && res.data) {
          this.close();
        }
      });
  }

}
