import { IconName } from './../../../shared/components/icon/icon.model';
import { Component } from '@angular/core';

@Component({
  selector: 'syn-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
  IconName: typeof IconName = IconName;
}
