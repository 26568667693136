import { FormatDate } from './../enum/date-format.enum';
import { SortDirection } from './../filter.interface';

export enum ColumnType {
    NUMBER = 'NUMBER',
    STRING = 'STRING',
    BOOLEEN = 'BOOLEEN',
    EMAIL = 'EMAIL',
    LINK = 'LINK',
    TEL = 'TEL',
    DATE = 'DATE'
}

export interface Column {
    field: string;
    type?: ColumnType;
    size?: number;
    sortable?: boolean;
    defaultSort?: boolean;
    format?: string | FormatDate;
    titleFn?: (row: any) => string;
    disableActionFn?: () => boolean;
    displayFn?: (row: any) => any;
}

export interface ColumnValue {
    item: any;
    field: string;
    value: any;
}

export interface TableOptions {
    addMenu?: boolean;
    editMenu?: boolean;
    deleteMenu?: boolean;
    exportMenu?: boolean;
    duplicateMenu?: boolean;
    showCheckboxes?: boolean
}

export enum ExportType {
    PDF = 'pdf',
    CSV = 'csv'
}

export interface SortOrder {
    field: string;
    direction: SortDirection;
}

export const ACTION_COL: Column = { field: 'actions' };