import { Pipe, PipeTransform } from "@angular/core";
import { DateService } from '../services/date.service';
import { FormatDate } from './../models/enum/date-format.enum';

@Pipe({ name: 'synDate', pure: false })
export class DatePipe implements PipeTransform {

    constructor(private dateService: DateService) {
    }

    transform(date: Date, format?: FormatDate): string {
        return this.dateService.format(date, format);
    }
}