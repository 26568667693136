import { IconName } from './../icon/icon.model';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'syn-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent {
  @Input() message: string;
  IconName: typeof IconName = IconName;
}
