<syn-modal [header]="'fichier.titre' | translate" (closeModal)="close()">
    <div class="relative" content>
        <input [hidden]="true" #fileInput type="file" name="fichiers[]" id="fichiers[]" accept="{{accept}}"
            (change)="handleFiles($event);" [multiple]="multiple" />
        <p *ngIf="choosingError" class="tWarn">{{'fichier.messageErrorChoix' | translate}}</p>
        <div fxLayout="row wrap" fxLayoutAlign="space-between start">
            <div fxLayout="column" fxLayoutAlign="center center">
                <p>
                    <img class="picture preview" [src]="currentFile?.url | previewFile"
                        alt="{{'fichier.preview'|translate}}" />
                </p>
                <p class="information">
                    {{ 'fichier.maxSize'| translate:{size: maxSize | fileSize} }}<br />
                    {{ 'fichier.formats'| translate:{extensions: extensions} }}
                </p>
                <p>
                    <syn-button color="primary" [disabled]=" loading"
                        [label]="(multiple ? 'fichier.messageChoixMultiple' : 'fichier.messageChoix') | translate"
                        (onclick)="fileInput.click()">
                    </syn-button>
                </p>
            </div>
            <div class="app-spacer" fxLayout="column" fxLayoutAlign="space-around start">
                <div *ngFor="let f of files; let i=index;" (click)="currentFile = f" fxLayout="row"
                    fxLayoutAlign="space-between center" class="fileItem smallFont pointer fullW"
                    [class.selected]="currentFile?.id === f.id">
                    <span [class.tWarn]="f.message?.length">
                        <span *ngIf="f.message?.length">&#9888;</span>
                        {{f.file.name}}<br />
                        <span *ngIf="f.message?.length">{{f.message}}</span>
                    </span>
                    <syn-icon (onclick)="deleteFile(i)" [title]="'shared.supprimer'|translate" [name]="IconName.DELETE"
                        classes="tWarn">
                    </syn-icon>
                </div>
            </div>
        </div>
        <syn-spinner [loading]="loading"></syn-spinner>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" footer>
        <syn-button color="warn" [disabled]="loading" [label]="'shared.quitter' | translate"
            (onclick)="close()"></syn-button>
        <syn-button color="primary" [label]="'shared.importer' | translate"
            [disabled]="loading || files.length === 0 || choosingError" (onclick)="upload()">
        </syn-button>
    </div>
</syn-modal>