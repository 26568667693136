import { Component, Input } from '@angular/core';
import { IconName } from './../icon/icon.model';

@Component({
  selector: 'syn-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() header: string;
  @Input() hasFooter: boolean = true;
  @Input() width: string = 'auto';
  @Input() icon: IconName = IconName.FILE;
  @Input() footerAlign: 'start' | 'end' = 'end';

}
