<div fxLayout="column" fxLayoutAlign="space-around start" class="syn-input">
    <label class="label" [class.label-required]="required" *ngIf="label?.length" [for]="idControl">{{label}}</label>
    <div fxLayout="row" class="fullW" fxLayoutAlign="start center">

        <input [hidden]="true" class="syn-form-control-input icon-info" type="text" [id]="idControl+'value'"
            [name]="idControl+'name'" (change)="onChangeValue()" (ngModelChange)="value = $event" [(ngModel)]="value"
            [disabled]="readonly" [required]="required" />

        <input class="syn-form-control-input icon-info" [class.has-errors]="errors && field.touched" type="text"
            [id]="idControl" [name]="idControl" (blur)="computeDate($event)" [attr.maxlength]="maxsize"
            [value]="value | synDate:format" [placeholder]="(('shared.dateFormat.'+format) | translate)|uppercase"
            [disabled]="readonly" [required]="required" />

        <button type="button" (click)="pickerState = !pickerState" cdkOverlayOrigin #datePickerBtn="cdkOverlayOrigin"
            [disabled]="readonly" class="date-suffix-btn pointer" role="button">
            <syn-icon [disabled]="readonly" classes="tWhite" size="lg" [name]="IconCalandar"></syn-icon>
        </button>
    </div>
    <syn-form-errors [field]="field" [messageGroupKey]="messageGroupKey" [globalErrors]="globalErrors">
    </syn-form-errors>
</div>
<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="datePickerBtn" [cdkConnectedOverlayHasBackdrop]="true"
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop" (backdropClick)="pickerState = false"
    [cdkConnectedOverlayPositions]="pickerPosition" [cdkConnectedOverlayOpen]="pickerState">
    <syn-date-picker [date]="value" (dateChange)="handleSelected($event)"></syn-date-picker>
</ng-template>