<syn-modal [header]="'menu.profil.changerPass' | translate" (closeModal)="close()">
    <div content>
        <form [formGroup]="passwordForm">
            <p>
                <syn-input [maxLength]="50" type="password" [required]="true"
                    label="{{'core.userProfile.oldMotdepasse' | translate}}" formControlName="oldPassword">
                </syn-input>
            </p>
            <p>
                <syn-input [maxLength]="50" type="password" label="{{'core.userProfile.motdepasse' | translate}}"
                    [required]="true" formControlName="newPassword">
                </syn-input>
            </p>
            <p>
                <syn-input [maxLength]="50" type="password" [required]="true" [globalErrors]="FormErrors.NOT_SAME"
                    label="{{'core.userProfile.confirmMotdepasse' | translate}}" formControlName="cnfPassword">
                </syn-input>
            </p>
        </form>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" footer>
        <syn-button color="warn" [label]="'shared.annuler' | translate" (onclick)="close()"></syn-button>
        <syn-button color="primary" [label]="'shared.valider' | translate" [disabled]="!passwordForm.valid"
            (onclick)="apply()"></syn-button>
    </div>
</syn-modal>