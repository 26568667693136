import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { debounceTime, filter, fromEvent } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserService } from './shared/api/user.service';
import { ResponseCode, SantynelleResponse } from './shared/models/dto/response.dto';
import { UserEnvironment } from './shared/models/request/user.request';
import { ApplicationService } from './shared/services/application.service';
import { SynDialogService } from './shared/services/dialog.service';
import { IoService } from './shared/services/io.service';
import { LanguageService } from './shared/services/language.service';
import { LoaderService } from './shared/services/loader.service';
import { SynSnackBarService } from './shared/services/snackbar.service';

@Component({
  selector: 'syn-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy {

  constructor(
    private langService: LanguageService,
    private dialogService: SynDialogService,
    private snackBarService: SynSnackBarService,
    private appService: ApplicationService,
    private ioService: IoService,
    public loaderService: LoaderService,
    private userService: UserService) {
    this.langService.loadLanguages();
  }

  ngAfterViewInit(): void {

    // Ecoute les changements de taille de fenêtre
    fromEvent(window, 'resize').pipe(debounceTime(500)).subscribe(evt => this.appService.resizingWindow.next(evt));

    this.userService.isConnected.pipe(filter(state => state)).subscribe(() => {
      // Chargement de l'environnement
      this.loaderService.toggleMainLoader.next({ state: true, sms: 'application.chargement_environnement' });

      this.userService.getEnvironnement().pipe(take(1)).subscribe((res: SantynelleResponse<UserEnvironment>) => {
        this.loaderService.toggleMainLoader.next({ state: false });
        if (res.code !== ResponseCode.ACTION_SUCCESS) {
          const { sms } = this.snackBarService.getResponseSMS(res?.code, undefined, false, 'application.environnement');
          this.dialogService.toggleMainDialog.next({
            state: true,
            data: {
              header: 'user.compte',
              content: sms,
              noLabel: 'menu.profil.deconnexion',
              noAction: () => {
                this.userService.logout();
                this.dialogService.toggleMainDialog.next({ state: false });
              }
            }
          });
        } else {
          // Initialisation socket
          this.ioService.initSocket();
        }
      });

      this.userService.checkSession();
    });
  }

  ngOnDestroy(): void {
    this.ioService.socket?.removeAllListeners();
    this.ioService.socket?.close();
  }
}
