import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { cloneDeep } from 'lodash';
import { ObjectUtils } from '../../utils/object.utils';
import { DateService } from './../../../services/date.service';
import { IconName } from './../../icon/icon.model';

@Component({
  selector: 'syn-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnChanges {
  @Input() date: Date = new Date();
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  currentMonth: number = DateService.currentMonth();
  currentYear: number = DateService.currentYear();
  dates: Date[][] = [];
  days: number[] = [];
  months = ObjectUtils.generateArray(1, 13).map(m => ({ value: m, label: `shared.calendar.months.${m}.long` }));
  years: any[] = [];
  IconName = IconName;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date) {
      this.updateDates();
    }
  }

  ngOnInit(): void {
    this.generateYears();
    this.generateDate();
  }

  private updateDates() {
    const dateTime = DateService.dateTime(this.date);
    this.currentMonth = dateTime.month;
    this.currentYear = dateTime.year;
    this.generateYears();
    this.generateDate();
  }

  generateDate() {
    const dates: Date[][] = [];
    let start = DateService.trunk(DateService.firstDateOfMonth(this.currentMonth, this.currentYear));
    const dayNumber = DateService.dayNumber(start);
    // First day is Monday => start on monday if first day of month is another day
    if (dayNumber > 1) {
      start = DateService.dateBeforeDays(dayNumber - 1, start);
    }
    // We have 6 weeks on 7 days = 42 days => add 41 days on start date to have last date
    const last = DateService.dateAfterDays(41, start);
    for (let i = 0; i < 6 && start.getTime() <= last.getTime(); i++) { // weeks
      dates[i] = [];
      for (let j = 0; j < 7; j++) { // days
        dates[i][j] = cloneDeep(start);
        start = DateService.dateAfterDays(1, start);
      }
    }
    this.days = dates[0].map(d => DateService.dayNumber(d));
    this.dates = dates;
  }

  generateYears() {
    this.years = ObjectUtils.generateArray(this.currentYear - 20, this.currentYear + 21).map(y => ({ value: y, label: y }));
  }

  selectYear(year: number) {
    this.currentYear = year;
    this.generateYears();
    this.generateDate();
  }

  changeMonth(next: boolean = true) {
    const date = next ? DateService.nextMonth(this.currentMonth, this.currentYear) : DateService.previousMonth(this.currentMonth, this.currentYear);
    this.currentMonth = date.getMonth() + 1;
    this.currentYear = date.getFullYear();
    this.generateDate();
  }

  changeYear(next: boolean = true) {
    this.currentYear = next ? this.currentYear + 1 : this.currentYear - 1;
    this.generateYears();
    this.generateDate();
  }

  isToday(date: Date): boolean {
    return DateService.equal(date);
  }

  isSelected(day: Date): boolean {
    return DateService.equal(day, this.date);
  }

  isOutOfMonth(date: Date): boolean {
    const dt = DateService.dateTime(date);
    return dt.month !== this.currentMonth || dt.year !== this.currentYear;
  }

  select(day: Date) {
    this.date = day;
    this.dateChange.next(this.date);
  }

}
