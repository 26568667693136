import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { SnackBarParam } from './../../models/snackBar.model';

@Component({
  selector: 'syn-snack-bar',
  templateUrl: './syn-snack-bar.component.html',
  styleUrls: ['./syn-snack-bar.component.scss']
})
export class SynSnackBarComponent implements OnDestroy {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarParam,
    public snackBarRef: MatSnackBarRef<SynSnackBarComponent>,
    public sanitizer: DomSanitizer) { }

  snackAction() {
    if (this.data.actionFn) {
      this.data.actionFn();
    }
    this.snackBarRef.dismiss();
  }

  ngOnDestroy(): void {
    this.snackBarRef?.containerInstance?.dispose();
  }
}
