import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileService } from "../services/file.service";

@Pipe({ name: 'toImage' })
export class ImageFilePipe implements PipeTransform {

    constructor(private fileService: FileService) {
    }

    transform(fileData: Buffer): string {
        const data = this.fileService.decodeFile(fileData);
        return data ?? './assets/images/photo.svg';
    }
}

@Pipe({ name: 'previewFile' })
export class PreviewFilePipe implements PipeTransform {

    constructor(private fileService: FileService, private sanitizer: DomSanitizer) {
    }

    transform(url: string): string | SafeUrl {
        return url ? this.sanitizer.bypassSecurityTrustUrl(url) : './assets/images/photo.svg';
    }
}

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
    transform(size: number): string {
        return FileService.convertSize(size);
    }
}