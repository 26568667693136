import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { SanModalModule } from '@san/tools';
import { SharedModule } from '../shared/shared.module';
import { AboutComponent } from './components/about/about.component';
import { ChangeEmailComponent } from './components/change-email/change-email.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { DesktopComponent } from './components/desktop/desktop.component';
import { HeaderComponent } from './components/header/header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { TopMenuComponent } from './components/top-menu/top-menu.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { DateInterceptor } from './interceptors/date.interceptor';
import { UrlInterceptor } from './interceptors/url.interceptor';

@NgModule({
  declarations: [
    HeaderComponent,
    DesktopComponent,
    PageNotFoundComponent,
    AboutComponent,
    UserProfileComponent,
    ChangePasswordComponent,
    ChangeEmailComponent,
    TopMenuComponent,
    SideMenuComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SanModalModule,
    MatSnackBarModule,
    TranslateModule.forChild()
  ],
  exports: [
    SharedModule,
    DesktopComponent,
    PageNotFoundComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: UrlInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ]
})
export class CoreModule { }
