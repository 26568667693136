import { IconName } from './../icon/icon.model';
import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'syn-button-icon',
  templateUrl: './button-icon.component.html',
  styleUrls: ['./button-icon.component.scss']
})
export class ButtonIconComponent {
  @Input() color: string = 'primary';
  @Input() label: string;
  @Input() icon: IconName = IconName.INFO;
  @Input() disabled: boolean = false;
  @Output() onclick: EventEmitter<void> = new EventEmitter<void>();
}
