import { Injectable } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { SessionKey } from '../models/session.keys';
import { environment } from './../../../environments/environment';
import { SynKeycloakService } from './../../core/services/keycloak.service';
import { ApplicationService } from './application.service';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    currentLang = environment.defaultLanguage;
    languages = environment.languages;
    kcLanguage: string;
    sessionLanguage: string = ApplicationService.readSession(SessionKey.CURRENT_LANG);
    loaded = false;

    constructor(private translateService: TranslateService, private keycloakService: SynKeycloakService) {
    }

    loadLanguages() {
        if (!this.loaded) {
            this.kcLanguage = this.keycloakService?.getTokenParsed()?.locale;
            if (this.sessionLanguage || this.kcLanguage) {
                this.currentLang = this.sessionLanguage ?? this.kcLanguage;
            }
            this.languages.filter((l: string) => l !== this.currentLang)
                .forEach((l: string) => this.translateService.reloadLang(l));
            this.translateService.setDefaultLang(this.currentLang);
            this.translateService.use(this.currentLang);
            ApplicationService.storeSession(SessionKey.CURRENT_LANG, this.currentLang);
            this.loaded = true;
        }
    }

    changeLanguage(code: string) {
        if (code) {
            this.translateService.use(code);
            this.currentLang = code;
            ApplicationService.storeSession(SessionKey.CURRENT_LANG, code);
        }
    }

    langaugeChange(): Observable<LangChangeEvent> {
        return this.translateService.onLangChange.asObservable();
    }

    translate(key: string, interpolateParams?: unknown | undefined) {
        return this.translateService.stream(key, interpolateParams);
    }

    iTranslate(key: string, interpolateParams?: unknown | undefined) {
        return this.translateService.instant(key, interpolateParams);
    }
}