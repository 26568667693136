import { Injectable } from "@angular/core";
import { ValidationErrors } from '@angular/forms';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})
export class ValidatorService {

    public static ERROR_NOT_SAME = 'notSame';
    public static ERROR_REQUIRED = 'required';
    public static ERROR_EQUAL = 'notEqual';
    public static ERROR_EMAIL = 'email';
    public static ERROR_NUMBER = 'notNumber';
    public static ERROR_MIN_LENGTH = 'minlength';
    public static ERROR_MAX_LENGTH = 'maxlength';
    public static ERROR_MAX = 'max';
    public static ERROR_MIN = 'min';
    public static ERROR_PATTERN = 'pattern';

    constructor(private langueService: LanguageService) {
    }

    handleMessage(errors: ValidationErrors) {
        const keys = Object.keys(errors);
        let message: string = '';
        switch (keys[0]) {
            case ValidatorService.ERROR_MIN_LENGTH:
                message = this.langueService.iTranslate(`validation.${keys[0]}`, { min: errors[ValidatorService.ERROR_MIN_LENGTH].requiredLength });
                break;
            case ValidatorService.ERROR_MAX_LENGTH:
                message = this.langueService.iTranslate(`validation.${keys[0]}`, { min: errors[ValidatorService.ERROR_MAX_LENGTH].requiredLength });
                break;
            case ValidatorService.ERROR_MAX:
                message = this.langueService.iTranslate(`validation.${keys[0]}`, { max: errors[ValidatorService.ERROR_MAX].max });
                break;
            case ValidatorService.ERROR_MIN:
                message = this.langueService.iTranslate(`validation.${keys[0]}`, { min: errors[ValidatorService.ERROR_MIN].min });
                break;
            case ValidatorService.ERROR_NOT_SAME:
            case ValidatorService.ERROR_EMAIL:
            case ValidatorService.ERROR_NUMBER:
            case ValidatorService.ERROR_PATTERN:
                message = this.langueService.iTranslate(`validation.${keys[0]}`);
                break;
            case ValidatorService.ERROR_REQUIRED:
            default:
                break;
        }
        return message;
    }


    public static isEmail(value: string): boolean {
        return !!value?.trim().toLowerCase().match(/^[\w-.]+@([\w-]+.)+[\w-]+$/);
    }

    public static isNumber(value: string): boolean {
        return !!value?.trim().toLowerCase().match(/^\d+$/);
    }

    public static numberPattern(digit?: number): string {
        return digit ? `^[0-9]{${digit}}$` : '^[0-9]$';
    }
}