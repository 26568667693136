import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ValidatorService } from './../../services/validators.service';

export interface GlobalError {
    code: string;
    label: string;
}

export function emailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value?.length && !ValidatorService.isEmail(control.value.toString())) {
            return { email: { value: control.value } };
        }
        return null;
    };
}

export function telValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value?.length && !ValidatorService.isNumber(control.value.toString())) {
            return { tel: { value: control.value } };
        }
        return null;
    };
}

export function numberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (control.value?.length && !ValidatorService.isNumber(control.value.toString())) {
            return { notNumber: { value: control.value } };
        }
        return null;
    };
}

export function confirmValidator(controlName: string, compareName?: string, isConfirmation: boolean = true, value?: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const ctl = control.get(controlName);
        const cmp = compareName ? control.get(compareName).value : value;
        if (ctl?.value?.toString().length && cmp?.toString().length && ctl.value !== cmp) {
            return isConfirmation ? { notSame: { value: false } } : { notEqual: { value: false } };
        }
        return null;
    };
}