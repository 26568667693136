import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { Directive, HostListener, Inject, Injector, OnDestroy } from "@angular/core";
import { DialogData } from '../../models/dialog.model';

@Directive()
export abstract class BaseDialogComponent implements OnDestroy {

    protected dialogRef: DialogRef<DialogData>;
    @Inject(DIALOG_DATA) public data: DialogData;

    closeIcon: boolean;

    protected constructor(protected injector: Injector) {
        this.dialogRef = this.injector.get(DialogRef<DialogData>);
        this.data = this.injector.get(DIALOG_DATA);
        this.closeIcon = !this.dialogRef.disableClose;
    }

    close(data?: DialogData) {
        this.dialogRef.close(data);
    }

    @HostListener('keydown.esc')
    onEsc() {
        if (this.closeIcon) {
            this.close();
        }
    }

    ngOnDestroy(): void {
        this.dialogRef?.containerInstance?.dispose();
    }
}