import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconName, IconSize, IconStyle } from './icon.model';

@Component({
  selector: 'syn-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  static nextID = 0;
  @Input() name: IconName | string;
  @Input() id: string = `icon-${IconComponent.nextID++}`;
  @Input() style: IconStyle = 'solid';
  @Input() size: IconSize = 'xl';
  @Input() classes: string = '';
  @Input() color: string;
  @Input() clickable: boolean = true;
  @Input() disabled: boolean = false;
  @Output() onclick: EventEmitter<void> = new EventEmitter<void>();

  clickOnIcon() {
    if (this.clickable && !this.disabled) {
      this.onclick.next();
    }
  }

}
