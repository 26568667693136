import { Injectable } from "@angular/core";
import { Droit, DroitCode } from './../models/dto/droit.dto';
import { TreeData } from './../models/interfaces/tree.interface';

@Injectable({ providedIn: 'root' })
export class TreeService {

    getChildren = (node: TreeData): TreeData[] => node.children ?? [];

    hasChild = (_: number, nodeData: TreeData) => nodeData.children?.length > 0;

    hasNoContent = (_: number, nodeData: TreeData) => nodeData.label === '';

    public static createDroitsTree(droits: Droit[]): TreeData[] {
        const admin = droits.find(d => d.code === DroitCode.APPLICATION_ADMIN);
        const baseDroits = droits.filter(d => d.code !== admin?.code && d.libelle.endsWith('.admin'));
        const tree: TreeData = { id: admin?.id ?? 1, label: `core.droits.${admin?.code}`, expand: true };
        tree.children = baseDroits.map(d => {
            const tree: TreeData = { id: d.id, label: `core.droits.${d.code}` };
            const prefix = d.code.split('_')[0];
            const ch = droits.filter(dt => dt.code !== d.code && dt.code.startsWith(`${prefix}_`));
            tree.children = ch?.map(c => ({ id: c.id, label: `core.droits.${c.code}` }));
            return tree;
        });
        return [tree];
    }

    public static updateChildrenState(children: TreeData[], state: boolean) {
        children?.forEach(c => c.checked = state);
    }

    public static findParent(node: TreeData, nodes: TreeData[], parent: TreeData): TreeData {
        let found = null;
        for (let i = 0; i < nodes.length && !found; i++) {
            const n = nodes[i];
            if (n.id === node.id) {
                return parent;
            } else if (n?.children?.length) {
                found = TreeService.findParent(node, n.children, n);
            }
        }
        return found;
    }

    public static updateLevel(nodes: TreeData[] = [], level: number = 1): TreeData[] {
        for (const node of nodes) {
            node.level = level;
            node.children = TreeService.updateLevel(node.children, level + 1);
        }
        return nodes;
    }

}