<syn-modal [header]="'menu.profil.desc' | translate" (closeModal)="close()">
    <div content>
        <form [formGroup]="userForm">
            <syn-tabs [selectedIndex]="0">
                <syn-tab label="{{'core.userProfile.profil' | translate}}">
                    <div class="center" content>
                        <p>
                            <img class="picture userPhoto" [src]="user?.photo | toImage"
                                alt="{{'user.photo'|translate}}" />
                        </p>
                        <p>
                            <syn-icon [title]="'user.modifierPhoto'|translate" [name]="IconName.CHANGE_PHOTO"
                                (onclick)="openFileLoader()" classes="tPrimary">
                            </syn-icon>

                            <syn-icon *ngIf="user?.photo" (onclick)="deletePhoto()"
                                [title]="'user.supprimerPhoto'|translate" [name]="IconName.REMOVE_FILE"
                                classes="tWarn"></syn-icon>
                        </p>
                        <p>
                            {{'user.creationCompte' | translate }}
                            {{user.datecreation|synDate:(FormatDate.DayMonthYearHourMin|translate)}}
                        </p>
                        <p>
                            {{'core.userProfile.matricule' | translate }} : {{user.matricule}}
                        </p>
                        <p>
                            {{'core.userProfile.email' | translate }} : {{user.email}}
                        </p>
                    </div>
                </syn-tab>
                <syn-tab label="{{'core.userProfile.informations' | translate}}">
                    <div content>
                        <p>
                            <syn-input [maxLength]="50" label="{{'core.userProfile.nom' | translate}}"
                                formControlName="nom">
                            </syn-input>
                        </p>
                        <p>
                            <syn-input [maxLength]="150" label="{{'core.userProfile.prenoms' | translate}}"
                                formControlName="prenoms">
                            </syn-input>
                        </p>
                        <p>
                            <syn-select label="{{'core.userProfile.sexe' | translate}}" [options]="sexeOptions"
                                formControlName="sexe">
                            </syn-select>
                        </p>
                        <p>
                            <syn-input type="tel" [maxLength]="20" label="{{'core.userProfile.telephone' | translate}}"
                                formControlName="telephone">
                            </syn-input>
                        </p>
                        <p>
                            <syn-input type="tel" [maxLength]="20" label="{{'core.userProfile.portable' | translate}}"
                                formControlName="portable">
                            </syn-input>
                        </p>
                        <p>
                            <syn-date label="{{'core.userProfile.date_naissance' | translate}}"
                                [maxDate]="maxDateNaissance" formControlName="date_naissance"></syn-date>
                        </p>
                        <p>
                            <syn-input [maxLength]="20" label="{{'core.userProfile.lieu_naissance' | translate}}"
                                formControlName="lieu_naissance">
                            </syn-input>
                        </p>
                        <p>
                            <syn-input type="textarea" [maxLength]="200"
                                label="{{'core.userProfile.adresse' | translate}}" formControlName="adresse">
                            </syn-input>
                        </p>
                    </div>
                </syn-tab>
                <syn-tab label="{{'core.userProfile.parametres' | translate}}">
                    <div content>
                        <p>
                            <syn-select label="{{'core.userProfile.langue' | translate}}" [options]="langueOptions"
                                formControlName="langue">
                            </syn-select>
                        </p>
                        <p>
                            <syn-select label="{{'core.userProfile.languePreferee' | translate}}"
                                [options]="prefereLangueOptions" formControlName="langue_preferee">
                            </syn-select>
                        </p>
                        <p>
                            <syn-checkbox idControl="profil-notif-email"
                                label="{{'core.userProfile.notif_email' | translate}}" formControlName="notif_email">
                            </syn-checkbox>
                        </p>
                        <p>
                            <syn-checkbox idControl="profil-notif-app"
                                label="{{'core.userProfile.notif_app' | translate}}" formControlName="notif_app">
                            </syn-checkbox>
                        </p>
                        <p>
                            <syn-checkbox idControl="profil-notif-sms"
                                label="{{'core.userProfile.notif_sms' | translate}}" formControlName="notif_sms">
                            </syn-checkbox>
                        </p>
                    </div>
                </syn-tab>
                <syn-tab label="{{'core.userProfile.access' | translate}}">
                    <div content>
                        <syn-button-toggle [options]="accessOptions" formControlName="accessOption"
                            (ngModelChange)="updateAccess($event)">
                        </syn-button-toggle>
                        <syn-tree [treeData]="accessTree" [withCheckBox]="false"></syn-tree>
                    </div>
                </syn-tab>
            </syn-tabs>
        </form>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center" footer>
        <syn-button color="warn" [label]="'shared.fermer' | translate" (onclick)="close()"></syn-button>
        <syn-button color="primary" [disabled]="userForm.invalid || reactiveService.loading"
            [label]="'shared.appliquer' | translate" (onclick)="apply()"></syn-button>
    </div>
</syn-modal>