<div *ngIf="menu.length" class="fullW" fxLayout="column" fxLayoutAlign="space-around start">
    <ng-container *ngFor="let m of menu;">
        <ng-container *ngIf="!!m.visible">
            <div class="side-menu" [class.childMenu]="padding > 5" (click)="clickOnMenu(m)"
                [ngStyle]="{'padding-left': padding + 'px'}">
                {{m.label | translate}}
            </div>
            <syn-side-menu *ngIf="m.items && m.expanded" [padding]="(padding + 10)" [menu]="m.items"></syn-side-menu>
        </ng-container>
    </ng-container>
</div>