import { Component, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseDialogComponent } from 'src/app/shared/components/utils/base-dialog.component';

@Component({
  selector: 'syn-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent extends BaseDialogComponent {

  constructor(protected override injector: Injector, public sanitizer: DomSanitizer) {
    super(injector);
  }

  cancel() {
    if (this.data.noAction) {
      this.data.noAction();
    }
    this.close();
  }

  valid() {
    if (this.data.yesAction) {
      this.data.yesAction();
    }
    this.close({ content: true });
  }

}
