export enum FileDestination {
    USER = 'USER',
}

export enum FileSizeOption {
    SMALL_SIZE = 'SMALL_SIZE',
    MEDIUM_SIZE = 'MEDIUM_SIZE',
    MAX_SIZE = 'MAX_SIZE',
}

export interface UploadRequest {
    ressourceId: string;
    destination: FileDestination;
    maxFiles: number;
    closeAfterUpload?: boolean;
    sizeOption?: FileSizeOption;
    isImage?: boolean;
}

export interface FileItem {
    id: number;
    file: File,
    url: string;
    message?: string;
}