import { AfterViewInit, Directive, EventEmitter, Injector, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NgControl, Validators } from '@angular/forms';
import { IconName } from './../icon/icon.model';

@Directive()
export abstract class BaseFormControl<T> implements OnInit, ControlValueAccessor, OnChanges, AfterViewInit {

    static nextID = 0;
    @Input() idControl: string = `form-control-${BaseFormControl.nextID++}`;
    @Input() messageGroupKey: string = 'validation';
    @Input() readonly: boolean = false;
    @Input() label: string = '';
    @Input() labelInfo: string;
    @Input() withIcon: boolean = true;
    @Input() icon: IconName = IconName.INFO;
    @Input() required: boolean = false;
    @Output() clickOnInfo: EventEmitter<T> = new EventEmitter<T>;
    private placeHolder: string;
    formControl: NgControl;
    private _value: T;

    protected _onChange = (_: any) => {
        // no content
    };
    _onTouched = () => {
        // no content
    };

    protected constructor(protected injector: Injector) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes['required'] || changes['readonly'])) {
            this.updateStates();
        }
    }

    ngOnInit() {
        this.formControl = this.injector.get(NgControl);
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    onChange(value: T) {
        this._onChange(value);
    }

    onKeyup() {
        this._onTouched();
        this._onChange(this.value);
    }

    onChangeValue() {
        this._onTouched();
        this.onChange(this.value);
    }

    onBlur() {
        this._onTouched();
    }

    @Input()
    get value(): T {
        return this._value;
    }

    set value(value: T) {
        this._value = value;
        this.onChange(value);
    }

    @Input() get placeholder() {
        return this.placeHolder ?? '';
    }

    set placeholder(plh: string) {
        this.placeHolder = plh;
    }

    get field() {
        return this.formControl?.control;
    }

    get errors() {
        return this.field?.errors;
    }

    abstract writeValue(obj: T): void;

    ngAfterViewInit(): void {
        this.updateStates();
    }

    private updateStates() {
        if (this.field) {
            setTimeout(() => {
                this.readonly = this.readonly || this.field.disabled;
                if (this.readonly) {
                    this.field.disable({ emitEvent: false });
                } else {
                    this.field.enable({ emitEvent: false });
                }

                if (this.field.hasValidator(Validators.required)) {
                    this.required = true;
                }

                if (this.required) {
                    this.field.addValidators(Validators.required);
                } else {
                    this.field.removeValidators(Validators.required);
                }
            });
        }
    }
}