import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'syn-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() color: string = 'primary';
  @Input() label: string = 'Label';
  @Input() disabled: boolean = false;
  @Output() onclick: EventEmitter<void> = new EventEmitter<void>();
}
