import { AfterViewInit, Component, Injector, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControl } from '../utils/form-control-base.component';

@Component({
  selector: 'syn-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true
    }
  ]
})
export class RadioButtonComponent extends BaseFormControl<string | number | any> implements OnInit, OnChanges, AfterViewInit {

  @Input() options: any[] = [];
  @Input() direction: 'vertical' | 'horizontal' = 'vertical';
  @Input() set labelField(field: string) {
    this.labelIndex = field?.length ? field : 'label';
  }
  @Input() set valueField(field: string) {
    this.valueIndex = field?.length ? field : 'value';
  }
  @Input() globalErrors: string[] | string;
  @Input() manageObject: boolean = false;
  selected: any;
  labelIndex: string = 'label';
  valueIndex: string = 'value';

  constructor(public override injector: Injector) {
    super(injector);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      super.ngOnChanges(changes);
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  override writeValue(value: string | number | any): void {
    this.selected = !this.manageObject ? this.options?.find(o => o[this.valueIndex] === value) : value;
    this.value = this.selectedValue;
  }

  get selectedLabel() {
    return this.selected && this.selected[this.valueIndex] ? this.selected[this.labelIndex] : '';
  }

  get selectedValue() {
    return !this.manageObject && this.selected ? this.selected[this.valueIndex] : this.selected;
  }

  get isVertical() {
    return this.direction === 'vertical';
  }

  get flexDirection() {
    return this.isVertical ? 'column' : 'row';
  }

  get flexAlign() {
    return this.isVertical ? 'space-around start' : 'space-between center';
  }

  isChecked(option: any) {
    return this.selected && this.selected[this.valueIndex] === option[this.valueIndex];
  }

  select(option: any) {
    this.selected = option;
    this.value = this.selectedValue;
    this.onChangeValue();
  }

}
