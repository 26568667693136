import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { AppConf } from '../interfaces/app-conf.interface';
import { AppconfService } from './app-conf.service';

@Injectable({
    providedIn: 'root',
})
export class SynKeycloakService {

    constructor(public kcService: KeycloakService) {
    }

    /**
     * Initialisation de l'application après chargement de keycloak et de la conf
     * @param keycloak le service keycloak
     * @param appConfService le service permettant la lecture du fichier app.conf.json
     */
    public static initializeKeyCloak(keycloak: KeycloakService, appConfService: AppconfService): () => Promise<any> {
        return (): Promise<any> => new Promise((resolve, reject) => {
            try {
                console.info('Initialisation de keycloak...');
                // Lecture de la configuration
                appConfService.init().subscribe(async (cf: AppConf) => {
                    // Initialisation de KeyCloak avec les informations de conf
                    await keycloak.init({
                        config: cf.keycloak.config,
                        initOptions: {
                            onLoad: 'check-sso',
                            checkLoginIframe: false
                        },
                        enableBearerInterceptor: true,
                        bearerExcludedUrls: ['/assets', '/styles', '/public']
                    });
                    resolve(null);
                });
            } catch (error) {
                reject(error);
            }
        });
    }

    /**
     * Récupère le token en tant qu'objet javascript
     */
    getTokenParsed(): any {
        return this.kcService.getKeycloakInstance().tokenParsed;
    }

    async logout() {
        await this.kcService.logout();
        this.kcService.clearToken();
    }
}
