export enum FormErrors {
    INVALID_DATE = 'notDateTime',
    REQUIRED = 'required',
    NUMBER = 'notNumber',
    MIN = 'min',
    MAX = 'max',
    EMAIL = 'email',
    TEL = 'tel',
    PATTERN = 'pattern',
    NOT_EQUAL = 'notEqual',
    NOT_SAME = 'notSame',
    PASSWORD = 'password',
    MINLENGTH = 'minlength',
    MAXLENGTH = 'maxlength'
}