<div *ngIf="showTitle" fxLayout="row" fxLayoutAlign="start center">
    <div class="table-title">{{(traductionGroup + '.titre') | translate}}</div>
</div>
<div fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="start center">
        <syn-icon *ngIf="options?.addMenu" [title]="'shared.nouvelElment' | translate" classes="tPrimary"
            (onclick)="newElement()" [name]="IconName.PLUS"></syn-icon>

        <syn-icon *ngIf="options?.exportMenu" [title]="'shared.exporter' | translate" classes="tPrimary"
            [cdkMenuTriggerFor]="menuExportActions.mainMenu" [name]="IconName.DOWNLOAD"></syn-icon>
    </div>
    <span class="app-spacer"></span>
    <syn-input [maxLength]="30" [placeholder]="'shared.recherches'|translate" [(ngModel)]="term"
        (ngModelChange)="search($event)">
    </syn-input>
</div>
<div class="table-container" tabindex="0">
    <table aria-describedby="datatable" cdk-table [dataSource]="rows">
        <ng-container *ngIf="showMenu" cdkColumnDef="actions">
            <th style="width: 50px;" class="head-cell actions" cdk-header-cell *cdkHeaderCellDef
                aria-label="row actions">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <syn-selection *ngIf="options.showCheckboxes" [value]="allChecked"
                        (valueChange)="toggleCheckboxes($event)"></syn-selection>

                    <syn-icon *ngIf="globalMenu.length" [title]="'shared.plusActions' | translate"
                        [name]="IconName.MENU" [cdkMenuTriggerFor]="menuGlobalActions.mainMenu"></syn-icon>
                </div>
            </th>
            <td class="row-cell" cdk-cell *cdkCellDef="let item;let i = index;">
                <syn-icon *ngIf="menu.length && !options.showCheckboxes" [name]="IconName.MENU"
                    [cdkMenuTriggerFor]="menuActions.mainMenu"></syn-icon>

                <span class="numero center" *ngIf="!menu.length && !options.showCheckboxes">{{i+1}}</span>

                <syn-selection *ngIf="options.showCheckboxes" [value]="item.checked"
                    (valueChange)="toggleCheckbox(i,$event)"></syn-selection>
            </td>
        </ng-container>

        <!-- Column -->
        <ng-container *ngFor="let col of dataColumns;" [cdkColumnDef]="col.field">
            <th cdk-header-cell *cdkHeaderCellDef>
                <div fxLayout="row" fxLayoutAlign="start center">
                    {{(traductionGroup +'.'+ col.field) | translate}}
                    <syn-icon *ngIf="col.sortable || col.sortable !== false" [name]="getIcon(col)" (onclick)="sort(col)"
                        classes="smallIcon"></syn-icon>
                </div>
            </th>
            <ng-container [ngSwitch]="col?.type">
                <ng-container *ngSwitchCase="ColumnType.BOOLEEN">
                    <td class="head-cell" cdk-cell *cdkCellDef="let item">
                        <syn-icon [name]="getBoolIcon(col,item)"
                            [classes]="!!item[col.field] ? 'tPrimary' : 'tWarn'"></syn-icon>
                    </td>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <td class="row-cell" [title]="getColTitle(col,item)" cdk-cell *cdkCellDef="let item">
                        {{getColValue(col,item)}}
                    </td>
                </ng-container>
            </ng-container>
        </ng-container>

        <tr class="head-row" cdk-header-row *cdkHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr class="body-row" cdk-row [class.pointer]="options.editMenu" [class.row-selected]="selectedRow === row"
            *cdkRowDef="let row; columns: displayedColumns;" (click)="rowClicked(row)" (dblclick)="rowDblClicked(row)">
        </tr>

        <tr class="body-row" *cdkNoDataRow>
            <td class="mat-cell center" [attr.colspan]="displayedColumns.length">
                {{'shared.aucunElment' | translate}}
            </td>
        </tr>
    </table>
    <syn-spinner [loading]="loading"></syn-spinner>
</div>
<syn-menu #menuActions [menuItems]="menu"></syn-menu>
<syn-menu #menuGlobalActions [menuItems]="globalMenu"></syn-menu>
<syn-menu #menuExportActions [menuItems]="exportMenu"></syn-menu>
<syn-pagination [class.hidden]="loading" [nbItems]="nbItems" (pageChange)="pageChange.next($event);"></syn-pagination>