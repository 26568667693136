<div class="pagination" *ngIf="nbItems > minSize" fxLayout="row" fxLayoutAlign="center center">
    <div style="width: 65px;">
        <syn-select id="pagination-size" [required]="true" [showPlaceholder]="false" [(ngModel)]="page.pageSize"
            [options]="sizeOptions" (ngModelChange)="sizeChange($event)">
        </syn-select>
    </div>
    <syn-icon classes="tPrimary" [title]="'shared.pagination.premier' | translate" [disabled]="page.currentPage === 1"
        [name]="IconName.FIRST" (onclick)="firstPage()"></syn-icon>
    <syn-icon classes="tPrimary" [title]="'shared.pagination.precedent' | translate" [disabled]="page.currentPage === 1"
        [name]="IconName.PREVIOUS" (onclick)="previousPage()"></syn-icon>
    <div class="smallFont">
        P. {{page.currentPage}} / {{page.nbPage}}
        ({{page.nbRows + ' ' + ('shared.pagination.elements'|translate)}})
    </div>
    <syn-icon classes="tPrimary" [title]="'shared.pagination.suivant' | translate"
        [disabled]="page.currentPage === page.nbPage" [name]="IconName.NEXT" (onclick)="nextPage()"></syn-icon>
    <syn-icon classes="tPrimary" [title]="'shared.pagination.dernier' | translate"
        [disabled]="page.currentPage === page.nbPage" [name]="IconName.LAST" (onclick)="lastPage()"></syn-icon>
</div>