import { Injectable } from '@angular/core';
import { DateTime, DurationLikeObject, Interval } from 'luxon';
import { FormatDate } from './../models/enum/date-format.enum';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class DateService {

  constructor(private langueService: LanguageService) { }

  format(date: Date, format?: FormatDate): string {
    format = format ?? FormatDate.DayMonthYear;
    date = date ?? new Date();
    return DateTime.fromISO(date.toISOString()).toFormat(this.langueService.iTranslate(`shared.dateFormat.${format}`));
  }

  transform(date: string, format?: FormatDate): Date {
    format = format ?? FormatDate.DayMonthYear;
    try {
      const dt = DateTime.fromFormat(date, this.langueService.iTranslate(`shared.dateFormat.${format}`));
      return dt.isValid ? dt.toJSDate() : null;
    } catch (error) {
      return null;
    }
  }

  getDateSize(format: FormatDate): number {
    return this.langueService.iTranslate(`shared.dateFormat.${format}`).toString().length;
  }

  /**
   * Convertit toutes les dates en string en OmpDate
   * @param item
   */
  static recursiveForEach(item: any): void {
    if (item !== null && item !== undefined) {
      if (Array.isArray(item)) {
        // Array
        item.forEach((v: any) => DateService.recursiveForEach(v));
      } else if (typeof item === 'object') {
        // Object
        DateService.interceptDatesInObject(item);
      }
    }
  }

  /**
   * Intercepte toutes les dates en string en Date dans un object
   * @param item
   */
  static interceptDatesInObject(item: any): void {
    const keys = Object.keys(item);
    for (const key of keys) {
      const value = item[key];
      if (!value || value === '' || key === 'Build-DateTime' || key === 'buildTime') {
        // Date de construction de l'application ou pas de value, on ne traite pas
        continue;
      }

      if (typeof value === 'string' && DateService.isDateRegexMatch(value)) {
        item[key] = new Date(value);
      } else {
        DateService.recursiveForEach(value);
      }
    }
  }

  /**
   * Check if string is on date format
   *
   * @param item
   */
  static isDateRegexMatch(item: string): RegExpMatchArray | null {
    return item.match(/(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})([+-](\d{2}):(\d{2}))?Z?/);
  }

  static dateDiff(start: Date = new Date(), end: Date = new Date(), units: keyof DurationLikeObject = 'hours'): number {
    return Interval.fromDateTimes(start, end).length(units);
  }

  static goToMonthYear(year?: number, month?: number, day?: number): DateTime {
    const date = new Date();
    if (day) {
      date.setDate(day);
    }
    if (month) {
      date.setMonth(month - 1);
    }
    if (year) {
      date.setFullYear(year);
    }
    return DateTime.fromJSDate(date);
  }

  static dateTime(date: Date = new Date()): DateTime {
    return DateTime.fromJSDate(date);
  }

  static daysNumberInMonth(month?: number, year?: number): number {
    return DateService.goToMonthYear(year, month).daysInMonth;
  }

  static daysNumberInYear(year?: number): number {
    return DateService.goToMonthYear(year).daysInYear;
  }

  static firstDateOfMonth(month?: number, year?: number): Date {
    return DateService.goToMonthYear(year, month).startOf('month').toJSDate();
  }

  static lastDateOfMonth(month?: number, year?: number): Date {
    return DateService.goToMonthYear(year, month).endOf('month').toJSDate();
  }

  static weekOfFirstDateOfMonth(month?: number, year?: number): number {
    return DateService.goToMonthYear(year, month).startOf('month').weekNumber;
  }

  static weekOfLastDateOfMonth(month?: number, year?: number): number {
    return DateService.goToMonthYear(year, month).endOf('month').weekNumber;
  }

  static firstWeekOfYear(year?: number): number {
    return DateService.goToMonthYear(year).startOf('year').weekNumber;
  }

  static lastWeekOfYear(year?: number): number {
    return DateService.goToMonthYear(year).endOf('year').weekNumber;
  }

  static currentDate(): number {
    return DateTime.now().day;
  }

  static currentMonth(): number {
    return DateTime.now().month;
  }

  static currentYear(): number {
    return DateTime.now().year;
  }

  static monthName(month?: number): string {
    return DateService.goToMonthYear(2020, month).monthLong;
  }

  static dayName(date: Date): string {
    return DateService.dateTime(date).weekdayShort;
  }

  static dayNumber(date: Date): number {
    return DateService.dateTime(date).weekday;
  }

  static tommorow(): Date {
    return DateService.dateTime().plus({ day: 1 }).toJSDate();
  }

  static yesterday(): Date {
    return DateService.dateTime().minus({ day: 1 }).toJSDate();
  }

  static nextMonth(month?: number, year?: number): Date {
    return DateService.goToMonthYear(year, month, 1).plus({ month: 1 }).toJSDate();
  }

  static previousMonth(month?: number, year?: number): Date {
    return DateService.goToMonthYear(year, month, 1).minus({ month: 1 }).toJSDate();
  }

  static dateAfterDays(days: number, date: Date = new Date()): Date {
    return DateService.dateTime(date).plus({ days }).toJSDate();
  }

  static dateBeforeDays(days: number, date: Date = new Date()): Date {
    return DateService.dateTime(date).minus({ days }).toJSDate();
  }

  static dateAfterWeeks(weeks: number, date: Date = new Date()): Date {
    return DateService.dateTime(date).plus({ weeks }).toJSDate();
  }

  static dateBeforeWeeks(weeks: number, date: Date = new Date()): Date {
    return DateService.dateTime(date).minus({ weeks }).toJSDate();
  }

  static dateAfterMonths(months: number, date: Date = new Date()): Date {
    return DateService.dateTime(date).plus({ months }).toJSDate();
  }

  static dateBeforeMonths(months: number, date: Date = new Date()): Date {
    return DateService.dateTime(date).minus({ months }).toJSDate();
  }

  static dateAfterYears(years: number, date: Date = new Date()): Date {
    return DateService.dateTime(date).plus({ years }).toJSDate();
  }

  static dateBeforeYears(years: number, date: Date = new Date()): Date {
    return DateService.dateTime(date).minus({ years }).toJSDate();
  }

  static trunk(date: Date = new Date()): Date {
    date.setHours(0, 0, 0, 0);
    return date;
  }

  static twoDigit(value: number): string {
    return value < 10 ? value.toString() : `0${value.toString()}`;
  }

  static equal(date1: Date, date2: Date = new Date(), dateOnly: boolean = true): boolean {
    if (dateOnly) {
      date1 = DateService.trunk(date1);
      date2 = DateService.trunk(date2);
    }
    return date1.getTime() === date2.getTime();
  }
}
